import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, ListGroup, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { RxCrossCircled } from "react-icons/rx";
import { useDispatch } from "react-redux";
import SelectFormGroup from "../../common/select-field";
import Loader from "../../common/loader";
import {
  teamUsers,
  teamForms,
  teamLocationParameter,
  teamVariable,
} from "../../hooks/teamInfo";
import { createAlert, updateAlert } from "../../hooks/alertSystem";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import "./style.css";

const AlertManagement = ({
  orgInfo,
  editAlert,
  onAlertManagement,
  updateMessage,
}) => {
  // Constants
    // const mqttTeams = [4, 5, 6]; prodMqtt Teams
  const mqttTeams = [247, 37, 48];
  const thresholdType = [
    { label: "Upper Bound", value: "upper_bound" },
    { label: "Lower Bound", value: "lower_bound" },
  ];

  const frequencyOptions = {
    form: [{ label: "Daily", value: "daily" }],
    threshold: {
      mqtt: [{ label: "Every 1 minute", value: "1_minute" }],
      lab_test: [{ label: "On Change", value: "on_change" }],
    },
    lab_test: [{ label: "Daily", value: "daily" }],
    mqtt: [{ label: "Every 1 minute", value: "1_minute" }],
  };

  const repeatOptions = {
    form: [{ label: "No Repeat", value: "no_repeat" }],
    threshold: {
      mqtt: [
        { label: "1 hour", value: "1_hour" },
        { label: "4 hours", value: "4_hours" },
        { label: "8 hours", value: "8_hours" },
        { label: "24 hours", value: "24_hours" },
        { label: "No Repeat", value: "no_repeat" },
      ],
      lab_test: [{ label: "No Repeat", value: "no_repeat" }],
    },
    lab_test: [{ label: "No Repeat", value: "no_repeat" }],
    mqtt: [
      { label: "1 hour", value: "1_hour" },
      { label: "4 hours", value: "4_hours" },
      { label: "8 hours", value: "8_hours" },
      { label: "24 hours", value: "24_hours" },
      { label: "No Repeat", value: "no_repeat" },
    ],
  };

  // State
  const defaultAlertData = {
    team: "",
    alertName: "",
    description: "",
    alertType: "",
    assignedUsers: [],
    notificationPreference: [],
    org: orgInfo?.orgId,
    frequency: "",
    repeatIfUnsolved: "",
  };

  const [state, setState] = useState({
    alertData: defaultAlertData,
    assignedUsers: [],
    selectedForms: [],
    users: [],
    teams: [],
    columns: [],
    locations: [],
    parameters: [],
    forms: [],
    errors: {},
    locationParameterPairs: [],
    initialAlertData: null,
    isAlertChange: false,
    frequencyType: [],
    repeatType: [],
    selectedLocation: null,
    dataTypes: [{ label: "Lab Test", value: "lab_test" }],
    alertType: [],
  });

  const dispatch = useDispatch();

  // Helper functions
  const formatTime = (time) => time ? time.split(":").slice(0, 2).join(":") : null;

  const checkLabTestFormInTeam = useCallback((teamId, teams) => {
    const team = teams.find((team) => Number(team.teamId) === Number(teamId));
    return team?.forms?.some((form) => form.formName === "Lab Test") || false;
  }, []);

  // Handlers
  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setState(prev => {
      let newAlertData = { ...prev.alertData, [name]: value };
      
      if (name === "team" || name === "alertType" || name === "dataType") {
        newAlertData = {
          ...newAlertData,
          assignedUsers: [],
          forms: [],
          locationParameterPairs: [],
          completionTime: "",
          notificationPreference: [],
          frequency: "",
          repeatIfUnsolved: "",
          variable: "",
          thresholdType: "",
          alertType: name === "team" ? "" : name === "alertType" ? value : prev.alertData.alertType,
          dataType: name === "team" || name === "alertType" ? "" : name === "dataType" ? value : prev.alertData.dataType,
        };
      }

      // Update frequency and repeatIfUnsolved when alertType or dataType changes
      if (name === "alertType" || name === "dataType") {
        const { frequency, repeat } = getFrequencyAndRepeatOptions(
          name === "alertType" ? value : prev.alertData.alertType,
          name === "dataType" ? value : prev.alertData.dataType
        );

        newAlertData = {
          ...newAlertData,
          frequency: frequency.length === 1 ? frequency[0]?.value : "",
          repeatIfUnsolved: repeat.length === 1 ? repeat[0]?.value : "",
        };
      }

      return {
        ...prev,
        alertData: newAlertData,
        errors: { ...prev.errors, [name]: undefined },
        ...(name === "team" || name === "alertType" || name === "dataType" ? {
          assignedUsers: [],
          selectedForms: [],
          locationParameterPairs: []
        } : {})
      };
    });
  }, []);

  const getFrequencyAndRepeatOptions = (alertType, dataType) => {
    let frequency = [];
    let repeat = [];

    if (alertType === "form") {
      frequency = frequencyOptions.form;
      repeat = repeatOptions.form;
    } else if (alertType === "threshold") {
      frequency = frequencyOptions.threshold[dataType] || [];
      repeat = repeatOptions.threshold[dataType] || [];
    } else if (alertType === "lab_test") {
      frequency = frequencyOptions.lab_test;
      repeat = repeatOptions.lab_test;
    } else if (alertType === "mqtt") {
      frequency = frequencyOptions.mqtt;
      repeat = repeatOptions.mqtt;
    }

    return { frequency, repeat };
  };

  const handleSelectChange = useCallback((type, selectedOption) => {
    const handlers = {
      user: () => {
        if (!selectedOption) return;
        const { value, label } = selectedOption;
        setState(prev => {
          if (prev.assignedUsers.some(u => u.userId === value)) return prev;
          
          const updatedAssignedUsers = [...prev.assignedUsers, { userId: value, userName: label }];
          return {
            ...prev,
            assignedUsers: updatedAssignedUsers,
            alertData: {
              ...prev.alertData,
              assignedUsers: updatedAssignedUsers.map(u => u.userId)
            },
            errors: { ...prev.errors, assignedUsers: undefined }
          };
        });
      },
      form: () => {
        if (!selectedOption) return;
        const { value, label } = selectedOption;
        setState(prev => {
          if (prev.selectedForms.some(f => f.formId === value)) return prev;
          
          const updatedSelectedForms = [...prev.selectedForms, { formId: value, formName: label }];
          return {
            ...prev,
            selectedForms: updatedSelectedForms,
            alertData: {
              ...prev.alertData,
              forms: updatedSelectedForms.map(f => f.formId)
            },
            errors: { ...prev.errors, forms: undefined }
          };
        });
      },
      location: () => {
        const location = selectedOption?.value || "";
        setState(prev => ({
          ...prev,
          alertData: { ...prev.alertData, location },
          selectedLocation: selectedOption?.value || null
        }));
      },
      parameter: () => {
        const parameter = selectedOption?.value || "";
        setState(prev => {
          if (!prev.alertData.location || !parameter) return prev;
          
          const newPair = { location: prev.alertData.location, parameter };
          const updatedPairs = [...prev.locationParameterPairs, newPair];
          return {
            ...prev,
            locationParameterPairs: updatedPairs,
            alertData: {
              ...prev.alertData,
              location: "",
              parameter: "",
              locationParameterPairs: updatedPairs
            }
          };
        });
      }
    };

    handlers[type]();
  }, []);

  const handleRemoveItem = useCallback((type, id) => {
    console.log("Removing item of type:", type, "with id:", id);
    setState(prev => {
      if (type === "user") {
        const updatedAssignedUsers = prev.assignedUsers.filter(user => user.userId !== id);
        return {
          ...prev,
          assignedUsers: updatedAssignedUsers,
          alertData: {
            ...prev.alertData,
            assignedUsers: updatedAssignedUsers.map(user => user.userId)
          }
        };
      } else if (type === "form") {
        const updatedSelectedForms = prev.selectedForms.filter(form => form.formId !== id);
        return {
          ...prev,
          selectedForms: updatedSelectedForms,
          alertData: {
            ...prev.alertData,
            forms: updatedSelectedForms.map(form => form.formId)
          }
        };
      } else if (type === "location") {
        const updatedPairs = prev.locationParameterPairs.filter((_, i) => i !== id);
        return {
          ...prev,
          locationParameterPairs: updatedPairs,
          alertData: {
            ...prev.alertData,
            locationParameterPairs: updatedPairs
          }
        };
      }
      return prev;
    });
  }, []);

  const handleNotification = useCallback((event) => {
    const { checked, value } = event.target;
    setState(prev => ({
      ...prev,
      alertData: {
        ...prev.alertData,
        notificationPreference: checked
          ? [...prev.alertData.notificationPreference, value]
          : prev.alertData.notificationPreference.filter(pref => pref !== value)
      },
      errors: { ...prev.errors, notificationPreference: undefined }
    }));
  }, []);

  const handleClear = useCallback(() => {
    setState(prev => ({
      ...prev,
      alertData: {
        ...defaultAlertData,
        org: orgInfo?.orgId
      },
      assignedUsers: [],
      selectedForms: [],
      locationParameterPairs: [],
      errors: {}
    }));
  }, [orgInfo]);

  // API calls
  const fetchTeamUsers = useCallback(async (teamId) => {
    dispatch(showLoader());
    try {
      const response = await teamUsers(teamId);
      setState(prev => ({
        ...prev,
        users: response.data.map(user => ({
          value: user.userId,
          label: user.userEmail
        })),
        ...(!editAlert ? { assignedUsers: [] } : {})
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch, editAlert]);

  const fetchTeamForms = useCallback(async (teamId) => {
    dispatch(showLoader());
    try {
      const response = await teamForms(teamId);
      setState(prev => ({
        ...prev,
        forms: response.data.map(form => ({
          value: form.formId,
          label: form.formName
        }))
      }));
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch]);

  const fetchTeamLocationParameter = useCallback(async (teamId) => {
    dispatch(showLoader());
    try {
      const response = await teamLocationParameter(teamId);
      setState(prev => ({
        ...prev,
        locations: response.data.location.map(lc => ({
          value: lc,
          label: lc
        })),
        parameters: response.data.parameter.map(pr => ({
          value: pr,
          label: pr
        })),
        columns: response.data.location.flatMap(location =>
          response.data.parameter.map(parameter => ({
            value: `${location} : ${parameter}`,
            label: `${location} : ${parameter}`
          }))
        )
      }));
    } catch (error) {
      console.error("Error fetching location parameters:", error);
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch]);

  const fetchTeamVariable = useCallback(async (teamId) => {
    dispatch(showLoader());
    try {
      const response = await teamVariable(teamId);
      setState(prev => ({
        ...prev,
        columns: response.data.map(lc => ({
          value: lc,
          label: lc
        }))
      }));
    } catch (error) {
      console.error("Error fetching variables:", error);
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch]);

  // Effects
  useEffect(() => {
    if (state.initialAlertData) {
      setState(prev => ({
        ...prev,
        isAlertChange: JSON.stringify(prev.alertData) !== JSON.stringify(prev.initialAlertData)
      }));
    }
  }, [state.alertData, state.initialAlertData]);

  useEffect(() => {
    if (!orgInfo) return;

    const teams = orgInfo.teams
      .filter(team => team.teamName !== "Master Data Management")
      .map(({ teamId, teamName }) => ({ teamId, teamName }));

    setState(prev => ({ ...prev, teams }));

    if (editAlert) {
      const commonFields = {
        team: editAlert.team,
        org: editAlert.org,
        alertName: editAlert.alert_name,
        description: editAlert.description,
        alertType: editAlert.alert_type,
        notificationPreference: editAlert.notification_preferences,
        assignedUsers: editAlert.assigned_users.map(user => user.userId),
        frequency: editAlert.frequency,
        repeatIfUnsolved: editAlert.repeat_if_unsolved
      };

      const typeSpecificFields = {
        form: {
          completionTime: formatTime(editAlert.completion_time),
          forms: editAlert.forms.map(form => form.formId)
        },
        lab_test: {
          completionTime: formatTime(editAlert.completion_time),
          locationParameterPairs: editAlert.select_location_parameter
        },
        threshold: {
          dataType: editAlert.select_datatype,
          variable: editAlert.select_variable,
          thresholdType: editAlert.threshold_type,
          thresholdValue: editAlert.threshold_value,
          minValue: editAlert.minimum_value,
          maxValue: editAlert.maximum_value
        }
      }[editAlert.alert_type] || {};

      const updateFields = { ...commonFields, ...typeSpecificFields };
      
      setState(prev => ({
        ...prev,
        alertData: updateFields,
        initialAlertData: updateFields,
        assignedUsers: editAlert.assigned_users.map(user => ({
          userId: user.userId,
          userName: user.userEmail
        })),
        selectedForms: editAlert.forms || [],
        locationParameterPairs: editAlert.select_location_parameter || []
      }));
    }
  }, [orgInfo, editAlert]);

  useEffect(() => {
    if (state.alertData.team) {
      const alertTypesSet = new Set();
      const checkLabTestForm = checkLabTestFormInTeam(state.alertData.team, orgInfo?.teams || []);

      if (mqttTeams.includes(state.alertData.team)) {
        alertTypesSet.add(JSON.stringify({ label: "MQTT", value: "mqtt" }));
        alertTypesSet.add(JSON.stringify({ label: "Threshold", value: "threshold" }));
      }
      
      if (checkLabTestForm) {
        alertTypesSet.add(JSON.stringify({ label: "Lab Test", value: "lab_test" }));
        alertTypesSet.add(JSON.stringify({ label: "Threshold", value: "threshold" }));
      }
      
      alertTypesSet.add(JSON.stringify({ label: "Form", value: "form" }));

      setState(prev => ({
        ...prev,
        alertType: Array.from(alertTypesSet).map(item => JSON.parse(item))
      }));
    }
  }, [state.alertData.team, orgInfo?.teams, checkLabTestFormInTeam]);

  useEffect(() => {
    if (!state.alertData.alertType) return;

    const { frequency, repeat } = getFrequencyAndRepeatOptions(
      state.alertData.alertType,
      state.alertData.dataType
    );

    setState(prev => ({
      ...prev,
      frequencyType: frequency,
      repeatType: repeat,
      ...(!editAlert ? {
        alertData: {
          ...prev.alertData,
          frequency: frequency.length === 1 ? frequency[0]?.value : "",
          repeatIfUnsolved: repeat.length === 1 ? repeat[0]?.value : ""
        }
      } : {})
    }));
  }, [state.alertData.alertType, state.alertData.dataType, editAlert]);

  useEffect(() => {
    if (state.alertData.team) {
      fetchTeamUsers(state.alertData.team);
    }
  }, [state.alertData.team, fetchTeamUsers]);

  useEffect(() => {
    if (state.alertData.alertType === "form" && state.alertData.team) {
      fetchTeamForms(state.alertData.team);
    }
  }, [state.alertData.alertType, state.alertData.team, fetchTeamForms]);

  useEffect(() => {
    if (state.alertData.alertType === "lab_test" && state.alertData.team) {
      fetchTeamLocationParameter(state.alertData.team);
    }
  }, [state.alertData.alertType, state.alertData.team, fetchTeamLocationParameter]);

  useEffect(() => {
    if (state.alertData.dataType === "mqtt" && state.alertData.team) {
      fetchTeamVariable(state.alertData.team);
    }
    if (state.alertData.dataType === "lab_test" && state.alertData.team) {
      fetchTeamLocationParameter(state.alertData.team);
    }
  }, [state.alertData.dataType, state.alertData.team, fetchTeamVariable, fetchTeamLocationParameter]);

  useEffect(() => {
    if (state.alertData.alertType === "threshold" && mqttTeams.includes(state.alertData.team)) {
      setState(prev => ({
        ...prev,
        dataTypes: [
          { label: "MQTT", value: "mqtt" },
          { label: "Lab Test", value: "lab_test" }
        ]
      }));
    }
  }, [state.alertData.alertType, state.alertData.team]);

  // Save handler
  const handleSave = useCallback(async () => {
    const { alertData, assignedUsers, selectedForms, users, forms } = state;
    const alertType = alertData.alertType;
    const newErrors = {};

    // Common validations
    if (!alertData.team) newErrors.team = "Team is required";
    if (!alertData.alertName) newErrors.alertName = "Alert Name is required";
    if (!alertData.description) newErrors.description = "Description is required";
    if (!alertData.alertType) newErrors.alertType = "Alert Type is required";
    
    if (!alertData.assignedUsers?.length || !assignedUsers.length) {
      newErrors.assignedUsers = users?.length === 0 && alertData.team
        ? "Please select a different team or ensure the team has an email."
        : "Email is required";
    }
    
    if (!alertData.notificationPreference.length) {
      newErrors.notificationPreference = "Notification Preferences is required";
    }
    
    if (!alertData.frequency || !alertData.alertType) {
      newErrors.frequency = "Frequency is required";
    }
    
    if (!alertData.repeatIfUnsolved || !alertData.alertType) {
      newErrors.repeatIfUnsolved = "Repeat if Unsolved is required";
    }

    // Type-specific validations
    if (alertType === "threshold") {
      if (!alertData.dataType) newErrors.dataType = "Data Type is required";
      if (!alertData.variable) newErrors.variable = "Variable is required";
      if (!alertData.thresholdType) newErrors.thresholdType = "Threshold Type is required";
      
      if (["upper_bound", "lower_bound"].includes(alertData.thresholdType)) {
        if (!alertData.thresholdValue) newErrors.thresholdValue = "Threshold Value is required";
      } else {
        if (!alertData.minValue) newErrors.minValue = "Minimum Value is required";
        if (!alertData.maxValue) newErrors.maxValue = "Maximum Value is required";
      }
    } else if (alertType === "form") {
      if (!alertData.forms?.length && !selectedForms.length) {
        newErrors.forms = forms?.length === 0
          ? "Please select a different team or ensure the team has a form."
          : "Form is required";
      }
      if (!alertData.completionTime) newErrors.completionTime = "Completion Time is required";
    } else if (alertType === "lab_test") {
      if (!alertData.locationParameterPairs?.length) {
        newErrors.locationParameterPairs = "At least one location-parameter pair is required";
      }
      if (!alertData.completionTime) newErrors.completionTime = "Completion Time is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setState(prev => ({ ...prev, errors: newErrors }));
      return;
    }

    const apiCall = editAlert 
      ? () => updateAlert(alertData, editAlert.id)
      : () => createAlert({ ...alertData, org: orgInfo?.orgId });

    dispatch(showLoader());
    try {
      const response = await apiCall();
      if (response?.success) {
        updateMessage("success", response.message);
        onAlertManagement();
      }
    } catch (error) {
      updateMessage("error", error.message || "Error occurred while processing alert");
    } finally {
      dispatch(hideLoader());
    }
  }, [state, editAlert, orgInfo, dispatch, updateMessage, onAlertManagement]);

  const renderAdditionalFields = () => {
    switch (state.alertData.alertType) {
      case "threshold":
        return (
          <>
            <SelectFormGroup
              controlId="dataType"
              label="Select Data Type"
              options={state.dataTypes}
              value={state.alertData.dataType}
              onChange={handleChange}
              error={state.errors.dataType}
              placeholder="Select Data Type"
              isInvalid={!!state.errors.dataType}
            />
            <SelectFormGroup
              controlId="variable"
              label="Select Variable"
              options={state.columns}
              value={state.alertData.variable}
              onChange={handleChange}
              error={state.errors.variable}
              placeholder="Select Variable"
              isInvalid={!!state.errors.variable}
            />
            <SelectFormGroup
              controlId="thresholdType"
              label="Threshold Type"
              options={thresholdType}
              value={state.alertData.thresholdType}
              onChange={handleChange}
              error={state.errors.thresholdType}
              placeholder="Select Threshold Type"
              isInvalid={!!state.errors.thresholdType}
            />
            {state.alertData.thresholdType &&
              (state.alertData.thresholdType === "upper_bound" ||
                state.alertData.thresholdType === "lower_bound" ? (
                <Form.Group
                  as={Row}
                  controlId="thresholdValue"
                  className="mb-3"
                >
                  <Form.Label column sm={2}>
                    Threshold Value <span className="text-danger">*</span>
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="thresholdValue"
                      value={state.alertData.thresholdValue || ""}
                      onChange={(e) => {
                        const input = e.target.value;
                        const regex = /^-?\d{0,10}(\.\d{0,2})?$/;
                        const invalidPatterns = /--|\.\.|-\d+-/;
                        if (
                          (regex.test(input) && !invalidPatterns.test(input)) ||
                          input === ""
                        ) {
                          handleChange(e); // Only update valid values
                        }
                      }}
                      placeholder="Enter Threshold Value"
                      isInvalid={!!state.errors.thresholdValue}
                      onKeyDown={(e) => {
                        if (["e", "E", "+"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {state.errors.thresholdValue}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              ) : (
                <>
                  <Form.Group as={Row} controlId="minValue" className="mb-3">
                    <Form.Label column sm={2}>
                      Minimum Value <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="minValue"
                        value={state.alertData.minValue}
                        onChange={handleChange}
                        placeholder="Enter Minimum Value"
                        isInvalid={!!state.errors.minValue}
                      />
                      <Form.Control.Feedback type="invalid">
                        {state.errors.minValue}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="maxValue" className="mb-3">
                    <Form.Label column sm={2}>
                      Maximum Value <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="maxValue"
                        value={state.alertData.maxValue}
                        onChange={handleChange}
                        placeholder="Enter Maximum Value"
                        isInvalid={!!state.errors.maxValue}
                      />
                      <Form.Control.Feedback type="invalid">
                        {state.errors.maxValue}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </>
              ))}
          </>
        );
      case "form":
        return (
          <>
            <Form.Group as={Row} controlId="forms" className="mb-3">
              <Form.Label column sm={2}>
                Select Form <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Select
                  name="forms"
                  value={null}
                  onChange={(option) => handleSelectChange("form", option)}
                  options={state.forms}
                  placeholder="Select Form"
                  isInvalid={!!state.errors.forms}
                  isOptionDisabled={(option) =>
                    state.selectedForms.some((f) => f.formId === option.value)
                  }
                  classNamePrefix={
                    !!state.errors.forms ? "invalid" : "select-dropdown"
                  }
                  menuPlacement="auto"
                />
                {state.errors.forms && (
                  <div className="invalid-feedback d-block">{state.errors.forms}</div>
                )}
                <div className="selected-forms-box mt-3">
                  <ListGroup>
                    <ListGroup.Item className="selected-forms-label">
                      <div className="form-title">Assigned Forms</div>

                      <div className="form-items-name">
                        {state.selectedForms.map((form, index) => (
                          <ListGroup.Item
                            key={index}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {form?.formName}
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={() =>
                                handleRemoveItem("form", form.formId)
                              }
                            >
                              <RxCrossCircled />
                            </Button>
                          </ListGroup.Item>
                        ))}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="completionTime" className="mb-3">
              <Form.Label column sm={2}>
                Completion Time <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="time"
                  name="completionTime"
                  value={state.alertData.completionTime}
                  onChange={handleChange}
                  isInvalid={!!state.errors.completionTime}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {state.errors.completionTime}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </>
        );
      case "lab_test":
        return (
          <>
            <Form.Group
              as={Row}
              controlId="locationAndParameter"
              className="mb-3"
            >
              <Form.Label column sm={2}>
                Select Location - Parameter{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Group as={Row}>
                  <Col sm={6}>
                    <Select
                      name="location"
                      options={state.locations}
                      value={
                        state.locations.find(
                          (option) => option.value === state.alertData.location
                        ) || null
                      }
                      onChange={(option) => handleSelectChange("location", option)}
                      placeholder="Select Location"
                      isClearable
                      menuPlacement="auto"
                      className="location-dropdown"
                      classNamePrefix={
                        state.errors.locationParameterPairs
                          ? "invalid"
                          : "select-dropdown"
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <Select
                      name="parameter"
                      options={state.parameters}
                      value={null}
                      onChange={(option) => handleSelectChange("parameter", option)}
                      placeholder="Select Parameter"
                      isClearable
                      isOptionDisabled={(option) =>
                        state.locationParameterPairs.some(
                          (pair) =>
                            pair.location === state.selectedLocation &&
                            pair.parameter === option.value
                        )
                      }
                      classNamePrefix={
                        state.errors.locationParameterPairs
                          ? "invalid"
                          : "select-dropdown"
                      }
                      menuPlacement="auto"
                    />
                  </Col>
                </Form.Group>
                {state.errors.locationParameterPairs && (
                  <div className="invalid-feedback d-block">
                    {state.errors.locationParameterPairs}
                  </div>
                )}
                <div className="location-parameter-box mt-3">
                  <ListGroup>
                    <ListGroup.Item className="location-parameter-label">
                      <div className="location-parameter-title">
                        Assigned Location - Parameter
                      </div>
                      <div className="location-parameter">
                        {state.locationParameterPairs.map((pair, index) => (
                          <ListGroup.Item
                            key={index}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div key={index}>
                              {pair.location} : {pair.parameter}
                            </div>
                            <Button
                              variant="link"
                              className="text-danger"
                              onClick={() =>
                                handleRemoveItem("location", index)
                              }
                            >
                              <RxCrossCircled />
                            </Button>
                          </ListGroup.Item>
                        ))}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="completionTime" className="mb-3">
              <Form.Label column sm={2}>
                Completion Time <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="time"
                  name="completionTime"
                  value={state.alertData.completionTime}
                  onChange={handleChange}
                  placeholder="Enter Completion Time"
                  isInvalid={!!state.errors.completionTime}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {state.errors.completionTime}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="new-aler-outer">
      <Loader />
      <h2>
        <i onClick={() => onAlertManagement()}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeLinecap="square"
              strokeMiterlimit="10"
              strokeWidth="48"
              d="M244 400L100 256l144-144M120 256h292"
            ></path>
          </svg>
        </i>{" "}
        {editAlert ? "Edit Alert Details" : "Create New Alert"}
      </h2>
      <Form>
        <SelectFormGroup
          controlId="team"
          label="Team"
          options={state.teams.map((team) => ({
            value: team.teamId,
            label: team.teamName,
          }))}
          value={state.alertData.team}
          onChange={handleChange}
          error={state.errors.team}
          placeholder="Select Team"
          isInvalid={!!state.errors.team}
        />
        <Form.Group as={Row} controlId="alertName" className="mb-3">
          <Form.Label column sm={2}>
            Alert Name <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              name="alertName"
              value={state.alertData.alertName}
              onChange={handleChange}
              placeholder="Enter Alert Name"
              isInvalid={!!state.errors.alertName}
            />
            <Form.Control.Feedback type="invalid">
              {state.errors.alertName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="description" className="mb-3">
          <Form.Label column sm={2}>
            Description <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              name="description"
              value={state.alertData.description}
              onChange={handleChange}
              rows={3}
              placeholder="Enter Description"
              isInvalid={!!state.errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {state.errors.description}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <SelectFormGroup
          controlId="alertType"
          label="Alert Type"
          options={state.alertType}
          value={state.alertData.alertType}
          onChange={handleChange}
          error={state.errors.alertType}
          placeholder="Select Alert Type"
          isInvalid={!!state.errors.alertType}
        />
        {state.alertData.alertType === "threshold" && (
          <div className="alert-message ">
            Alert if data is above or below predefined thresholds.
          </div>
        )}
        {state.alertData.alertType === "form" && (
          <div className="alert-message ">
            Alert when a form is not filled out in time.
          </div>
        )}
        {state.alertData.alertType === "lab_test" && (
          <div className="alert-message">
            Alert when a lab test is not filled out in time.
          </div>
        )}
        {state.alertData.alertType === "mqtt" && (
          <div className="alert-message">
            Alert if the MQTT broker fails to send data to Ensaras servers.
          </div>
        )}

        {renderAdditionalFields()}
        <>
          <SelectFormGroup
            controlId="frequency"
            label="Frequency"
            options={state.frequencyType || []}
            value={state.alertData.frequency}
            onChange={handleChange}
            error={state.errors.frequency}
            placeholder="Select Frequency"
            isInvalid={!!state.errors.frequency}
            isDisabled={state.frequencyType && state.frequencyType.length === 1}
          />
          <SelectFormGroup
            controlId="repeatIfUnsolved"
            label="Repeat if Unsolved"
            options={state.repeatType || []}
            value={state.alertData.repeatIfUnsolved}
            onChange={handleChange}
            error={state.errors.repeatIfUnsolved}
            placeholder="Select an Option"
            isInvalid={!!state.errors.repeatIfUnsolved}
            isDisabled={state.repeatType && state.repeatType.length === 1}
          />
        </>
        <Form.Group as={Row} controlId="assignedUsers" className="mb-3">
          <Form.Label column sm={2}>
            Assign To <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <Select
              name="assignedUsers"
              value={null}
              onChange={(option) => handleSelectChange("user", option)}
              options={state.users}
              placeholder="Type Email to Add the User"
              isOptionDisabled={(option) =>
                state.assignedUsers.some((u) => u.userId === option.value)
              }
              classNamePrefix={
                state.errors.assignedUsers ? "invalid" : "select-dropdown"
              }
              menuPlacement="auto"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
            />
            {state.errors.assignedUsers && (
              <div className="invalid-feedback d-block">
                {state.errors.assignedUsers}
              </div>
            )}
            <div className="assigned-users-box mt-3">
              <ListGroup>
                <ListGroup.Item className="assigned-users-label">
                  <div className="assign-title">Assigned Users</div>
                  <div className="assign-items-name">
                    {state.assignedUsers.map((user, index) => (
                      <ListGroup.Item
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {user.userName}
                        <Button
                          variant="link"
                          className="text-danger"
                          onClick={() => handleRemoveItem("user", user.userId)}
                        >
                          <RxCrossCircled />
                        </Button>
                      </ListGroup.Item>
                    ))}
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="notificationPreference"
          className="mb-3 align-items-center"
        >
          <Form.Label column sm={2}>
            Notification
            <br />
            <span className="notification-label">Preferences</span>{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={10}>
            <div className="notification-gtp">
              {["Email", "WhatsApp"].map((preference) => (
                <label
                  key={preference}
                  className={`custom-checkbox ${
                    state.errors.notificationPreference ? "invalid" : ""
                  }`}
                >
                  <span>{preference}</span>
                  <Form.Check
                    className="check-inner"
                    name="notificationPreference"
                    value={preference}
                    checked={state.alertData.notificationPreference.includes(
                      preference
                    )}
                    onChange={handleNotification}
                    isInvalid={!!state.errors.notificationPreference}
                  />
                </label>
              ))}
            </div>
            {state.errors.notificationPreference && (
              <div className="invalid-feedback d-block">
                {state.errors.notificationPreference}
              </div>
            )}
          </Col>
        </Form.Group>

        <div className="alt-buttons" style={{ marginTop: "20px" }}>
          {editAlert ? (
            <>
              <Button
                variant="outline-secondary"
                onClick={handleSave}
                style={{ marginRight: "10px" }}
                disabled={!state.isAlertChange}
              >
                Edit
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  onAlertManagement();
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outline-secondary"
                onClick={handleSave}
                style={{ marginRight: "10px" }}
              >
                Save
              </Button>
              <Button variant="outline-secondary" onClick={handleClear}>
                Clear
              </Button>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AlertManagement;
