import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AppNavBar from "../../components/navbar";
import AppFooter from "../../components/footer";
import "./style.css";
import OrgAddModal from "../../components/orgAdd";
import OrgEditModal from "../../components/orgEdit";
import { getRawOrgInfo } from "../../hooks/orgInfo";
import { setOrgData } from "../../store/orgSlice";
import { setUserInfo } from "../../store/userInfoSlice";
import Loader from "../../common/loader";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import MessagePopup from "../../components/messagePopup";

const Dashboard = () => {
  const [orgs, setOrgs] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [orgId, setOrgId] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [userDeatil, setUserDeatil] = useState({});

  const sortedOrgs = useMemo(
    () => [...orgs].sort((a, b) => a.orgName.localeCompare(b.orgName)),
    [orgs]
  );

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  useEffect(() => {
    const userInfo = {
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
      userId: localStorage.getItem("userId"),
      role: localStorage.getItem("role"),
    };
    setUserDeatil(userInfo);
    dispatch(setUserInfo(userInfo));
  }, [dispatch]);

  const getOrgInfo = () => {
    dispatch(showLoader());
    getRawOrgInfo()
      .then((response) => {
        setOrgs(response.data);
        setDataFetched(true);
        setLoading(false); // Set loading to false after data is fetched
        dispatch(hideLoader());
      })
      .catch((error) => {
        console.error("Failed to fetch organizations", error);
        setLoading(false); // Set loading to false even if there is an error
        dispatch(hideLoader());
      });
  };

  useEffect(() => {
    getOrgInfo();
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("orgLength", orgs.length);
    if (dataFetched && orgs.length === 1) {
      dispatch(setOrgData(orgs[0]));
      navigate(`/organization/${orgs[0].orgId}`);
    }
  }, [dataFetched, orgs, dispatch, navigate]);

  useEffect(() => {
    // Get the redirectUrl from the URL query params
    const urlParams = new URLSearchParams(window.location.search);
    let redirectUrl = urlParams.get("redirectUrl");

    // Check if there's a redirectUrl in the URL
    if (redirectUrl) {
      // Store the redirectUrl in sessionStorage or localStorage to use after signin
      sessionStorage.setItem("redirectUrl", redirectUrl);

      const token = localStorage.getItem("token");
      if (token) {
        // Redirect to the panel link
        window.location.href = sessionStorage.getItem("redirectUrl");

        // Clear the sessionStorage after using the link
        redirectUrl = sessionStorage.removeItem("redirectUrl");
      }
    }

    if (!localStorage.getItem("token")) {
      window.location.href = "/signin";
    }
  }, []);

  const handleOrgClick = (org) => {
    dispatch(setOrgData(org));
    navigate(`/organization/${org.orgId}?activeTab=overview`);
  };

  useEffect(() => {
    const activePath=localStorage.getItem("activePath")
    localStorage.removeItem("activeTab",)
    if(activePath) {
    navigate(activePath);
    localStorage.removeItem("activePath");
    }
  },[])

  if (loading) {
    return <Loader />; // Show loader while fetching data
  }

  return (
    <>
      <AppNavBar setEditMode={setEditMode}/>
      <MessagePopup
        message={message}
        messageType={messageType}
        onClose={() => setMessage("")}
      />
      <div className="dashboard-container">
        <div className="container">
          <div className="parent-class">
            <div className="welcome-text">
              <h2>
                Hi <span>{userDeatil.username}</span>. <br /> Select an
                organization to begin.
              </h2>
            </div>
            {userDeatil.role === "Ensaras Admin" && (
              <div className="edit-organization">
                {editMode ? (
                  <Button
                    variant="outline-secondary"
                    className="edit-btn"
                    onClick={() => setEditMode(false)}
                  >
                    Finish Edit
                  </Button>
                ) : (
                  <Button
                    variant="outline-primary"
                    onClick={() => setEditMode(true)}
                  >
                    Edit Organization
                  </Button>
                )}
              </div>
            )}
          </div>

          <Row className="organization-list-container justify-content-center mt-4">
            <Col md={12} lg={12}>
              <div className="organization-list-container-outer p-4">
                <h2>Organizations</h2>
                <ListGroup className="organization-list">
                  {sortedOrgs.map((org) => (
                    <ListGroup.Item
                      key={org.orgId}
                      className="organization-item d-flex justify-content-between organization-items-center"
                    >
                      <div
                        onClick={() => handleOrgClick(org)}
                        style={{ flexGrow: 1 }}
                        className="organization-name"
                      >
                        {org.orgName.length > 50
                          ? `${org.orgName.substring(0, 50)}...`
                          : org.orgName}
                      </div>
                      {editMode && org.orgName !== "Ensaras" && (
                        <div className="control-btns">
                          {userDeatil.role === "Ensaras Admin" && (
                            <Button
                              variant="link"
                              onClick={() => {
                                setOrgId(org.orgId);
                                setOrgName(org.orgName);
                                setShowEditModal(true);
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      )}
                    </ListGroup.Item>
                  ))}
                  {userDeatil.role === "Ensaras Admin" && (
                    <ListGroup.Item
                      className="organization-item add-organization"
                      onClick={() => {setShowAddModal(true);  setEditMode(false)}}
                    >
                      + Add Organization
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <AppFooter/>
      <OrgAddModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
      <OrgEditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        orgId={orgId}
        orgName={orgName}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
    </>
  );
};

export default Dashboard;
