import api from '../interceptors/axios'

async function getRawTeamInfo() {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`/api/team`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

async function orgTeam(orgId) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`/api/org-teams/${orgId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

async function teamUsers(teamId) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`/api/team-users/${teamId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

async function teamForms(teamId) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`/api/team-forms/${teamId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

async function teamLocationParameter(teamId) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`/api/fetch-location-parameter/${teamId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

async function teamVariable(teamId) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get(`/api/variables/${teamId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}



async function teamAlertList(teamId, data) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.post(`/api/alerts/list/${teamId}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data || 'An error occurred');
    }
}
export { getRawTeamInfo, orgTeam ,teamUsers,teamForms,teamLocationParameter,teamVariable,teamAlertList};
