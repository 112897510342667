import api from '../interceptors/axios'

async function getRawOrgInfo() {
    const token = localStorage.getItem('token');
    try {
        const response = await api.get('/api/org', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

export { getRawOrgInfo };