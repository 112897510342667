import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { resetPassword } from "../../hooks/resetPassword";
import "./style.css";
import { isEmpty } from "validator";
import { Reminder } from "../../utils/validation";

const ResetPasswordModal = ({ show, onHide, updateMessage }) => {
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [error, setError] = useState({
    newPassword: "",
    repeatPassword: "",
  });

  const handleResetPassword = () => {

    const errorStatus = { ...error };

    let passError = "";
    if (
      !newPassword.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/
      )
    ) {
      if (isEmpty(newPassword, { ignore_whitespace: true })) {
        passError = "New Password is required field.";
      } else if (newPassword.length < 8) {
        passError = "Password must have at least 8 characters.";
      } else if (!newPassword.match(/[a-z]/)) {
        passError = "Password must have at least 1 lowercase letter.";
      } else if (!newPassword.match(/[A-Z]/)) {
        passError = "Password must have at least 1 uppercase letter.";
      } else if (!newPassword.match(/\d/)) {
        passError = "Password must have at least 1 number.";
      } else if (!newPassword.match(/[a-zA-Z]/)) {
        passError = "Password must have at least 1 letter.";
      } else if (!newPassword.match(/[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]/)) {
        passError = "Password must have at least 1 special character.";
      }
    }
    if (passError) {
      errorStatus.newPassword = passError;
    }
    if (isEmpty(repeatPassword)) {
      errorStatus.repeatPassword = "Repeat New Password is required field.";
    }

    if (errorStatus.newPassword || errorStatus.repeatPassword) {
      setError(() => errorStatus);
      return;
    }
    if (newPassword !== repeatPassword) {
      errorStatus.repeatPassword = "New password and Repeat New password do not match.";
    }

    if (Object.values(errorStatus).some((value) => value !== "")) {
      setError(() => errorStatus);
      return;
    }

    if (newPassword === repeatPassword) {
      setPasswordsMatch(true);
      resetPassword({ new_password: newPassword })
        .then((res) => {})
        .catch((error) => {
          updateMessage(
            "error",
            `[${error.message}] Failed to reset password!`
          );
        });
      setNewPassword("");
      setRepeatPassword("");
      setError({
        newPassword: "",
        repeatPassword: "",
      });
      // Close the modal
      onHide();
    } else {
      setPasswordsMatch(false);
    }
  };

  const resetOnHide = () => {
    setNewPassword("");
    setRepeatPassword("");
    setError({
      newPassword: "",
      repeatPassword: "",
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={resetOnHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>New Password <span className="text-danger">*</span></Form.Label>
            <div className="email-fld">
            <Form.Control
              type="password"
              isInvalid={error.newPassword}
              placeholder="Enter New Password"
              required
              value={newPassword}
              onChange={(e) => {
                setError((prev) => ({ ...prev, newPassword: "" }));
                setNewPassword(e.target.value);
              }}
            />
            {!(error.newPassword || error.repeatPassword) && 
              (<Form.Text className="text-muted">
                {Reminder('password')}
              </Form.Text>)}
            <Form.Control.Feedback type="invalid">
              {error.newPassword}
            </Form.Control.Feedback>
            </div>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Repeat New Password <span className="text-danger">*</span></Form.Label>
            <div className="email-fld">
            <Form.Control
              type="password"
              placeholder="Repeat New Password"
              isInvalid={error.repeatPassword}
              required
              value={repeatPassword}
              onChange={(e) => {
                setError((prev) => ({ ...prev, repeatPassword: "" }));
                setRepeatPassword(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {error.repeatPassword}
            </Form.Control.Feedback>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>

      {passwordsMatch ? null : (
        <div className="pwd-not-match-msg">
          Passwords do not match. Please try again.
        </div>
      )}

      <Modal.Footer>
        <Button variant="primary" onClick={handleResetPassword} className="reset-password-btn">
          Reset Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPasswordModal;
