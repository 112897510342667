import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AppNavBar from "../../components/navbar";
import AppFooter from "../../components/footer";
import OrganizationOverview from "../../components/organizationOverview";
import AccountManagement from "../../components/accountManagement";
import AlertSystem from "../../components/alertSystem";
import MasterDataManagement from "../../components/masterDataManagement";
import { getRawOrgInfo } from "../../hooks/orgInfo";
import { setOrgData } from "../../store/orgSlice";
import { setUserInfo } from "../../store/userInfoSlice";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import Loader from "../../common/loader";
import "./style.css";

const OrganizationOverviewPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [alertManagePage, setAlertManagePage] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [alertEditMode, setAlertEditMode] = useState(false);
  const [editTeamId, setEditTeamId] = useState(null);
  const [key, setKey] = useState(searchParams.get("activeTab") || "overview");
  const orgInfo = useSelector((state) => state.org.orgData);
  const userInfo = useSelector((state) => state.user.userInfo) || {};
  const dispatch = useDispatch();

  const getOrgInfo = () => {
    dispatch(showLoader());
    getRawOrgInfo()
      .then((response) => {
        let orgData = response.data?.find(
          (item) => item.orgId === parseInt(id, 10)
        );
        if (orgData) {
          localStorage.setItem("orgLength", response.data.length);
          dispatch(setOrgData(orgData));
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.error("Error fetching organization info:", error);
        dispatch(hideLoader());
      });
  };

  useEffect(() => {
    const userInfo = {
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
      userId: localStorage.getItem("userId"),
      role: localStorage.getItem("role"),
    };
    dispatch(setUserInfo(userInfo));
  }, [dispatch]);

  useEffect(() => {
    if (!orgInfo || Object.keys(orgInfo).length === 0) {
      getOrgInfo();
    }
  }, [orgInfo, dispatch]);

  const handleTabSelect = (k) => {
    setKey(k);
    resetTabEdits()
    const params = new URLSearchParams(location.search);
    params.set("activeTab", k);
    navigate({ search: params.toString() });

    if (k !== "alerts") {
      setAlertManagePage(false);
    }
  };

  const resetTabEdits=()=>{
    setEditMode(() => false);
    setAlertEditMode(() => false);
    setEditTeamId(() => null);
  }

  const roleToTabs = {
    "Ensaras Admin": ["overview", "account", "alerts", "masterData"],
    "Client Admin": ["overview", "account", "masterData"],
    "Ensaras User": ["overview"],
    "Client User": ["overview"],
  };
  const accessibleTabs = roleToTabs[userInfo.role] || ["overview"];

  const renderContent = () => {
    localStorage.setItem("activeTab", key);
    switch (key) {
      case "overview":
        return (
          <OrganizationOverview
            activeTab={key}
            editTeamId={editTeamId}
            setEditTeamId={setEditTeamId}
          />
        );
      case "account":
        return (
          <AccountManagement editMode={editMode} setEditMode={setEditMode} />
        );
      case "alerts":
        return (
          <AlertSystem
            setKey={setKey}
            alertManagePage={alertManagePage}
            setAlertManagePage={setAlertManagePage}
            editMode={alertEditMode}
            setEditMode={setAlertEditMode}
          />
        );
      case "masterData":
        return <MasterDataManagement />;
      default:
        return null;
    }
  };

  return (
    <>
      <AppNavBar setKey={setKey} activeKey={key} organization={id} resetTabEdits={resetTabEdits} />
      {!orgInfo ? (
        <Loader />
      ) : (
        <div className="organization-overview-container">
          <div className="container">
            <div className="parent-class">
              <div className="welcome-text">
                <h2>
                  Hi {userInfo.username}. <br /> Welcome to{" "}
                  <span>{orgInfo?.orgName}</span>
                </h2>
              </div>
              {userInfo.role !== "Client User" &&
                localStorage.getItem("orgLength") > 1 && (
                  <div className="back-button">
                    <Link to="/dashboard">
                      <Button
                        variant="outline-secondary"
                        className="back-btn"
                        onClick={() => {navigate("/dashboard"); localStorage.removeItem("activePath");
                        }}
                      >
                        Back to Organization List
                      </Button>
                    </Link>
                  </div>
                )}
            </div>
            <div className="org-tabs">
              {accessibleTabs.length > 1 ? (
                <Tabs
                  id="organization-overview-tabs"
                  activeKey={key}
                  onSelect={handleTabSelect}
                  className={`mb-3 ${
                    userInfo.role === "Client Admin"
                      ? "client-admin"
                      : "global-admin"
                  }`}
                >
                  {accessibleTabs.includes("overview") && (
                    <Tab eventKey="overview" title="Organization Overview">
                      <OrganizationOverview
                        activeTab={key}
                        editTeamId={editTeamId}
                        setEditTeamId={setEditTeamId}
                      />
                    </Tab>
                  )}
                  {accessibleTabs.includes("account") && (
                    <Tab eventKey="account" title="Account Management">
                      <AccountManagement
                        editMode={editMode}
                        setEditMode={setEditMode}
                      />
                    </Tab>
                  )}
                  {accessibleTabs.includes("alerts") && (
                    <Tab eventKey="alerts" title="Alert Management">
                      <AlertSystem
                        alertManagePage={alertManagePage}
                        setAlertManagePage={setAlertManagePage}
                        editMode={alertEditMode}
                        setEditMode={setAlertEditMode}
                      />
                    </Tab>
                  )}
                  {accessibleTabs.includes("masterData") && (
                    <Tab eventKey="masterData" title="Master Data Management">
                      <MasterDataManagement />
                    </Tab>
                  )}
                </Tabs>
              ) : (
                <OrganizationOverview />
              )}
            </div>
            <div className="mobile-tab-content">{renderContent()}</div>
          </div>
        </div>
      )}
      <AppFooter />
    </>
  );
};

export default OrganizationOverviewPage;
