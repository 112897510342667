import React, { useState, useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import { SignInPage } from "./pages/signin";
import { FormPage } from "./pages/form";
import { AnomalyListPage } from "./pages/anomalyList";
import { Terms } from "./pages/terms";
import ForgotPassword from "./components/passwordReset";
import ResetPasswordRequest from "./components/resetPasswordRequest";
import VerificationModal from "./components/emailVerify";
import SetPassword from "./components/passwordSet";
import Dashboard from "./pages/dashboard";
import OrganizationOverviewPage from "./pages/organization";
import UserAuthentication from "./components/userAuthentication";

import MobileVerification from "./components/mobileVerify";
import AlertList from "./pages/alerts";

function AppRoutes() {
  const [token, setToken] = useState("");

  useEffect(() => {
    const previousToken = localStorage.getItem("token");
    if (previousToken) {
      setToken(previousToken);
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Dashboard />} />
      <Route
        exact
        path="/signin"
        element={<SignInPage token={token} setToken={setToken} />}
      />
      <Route path="/form/:id" element={<FormPage token={token} />} />
      <Route
        path="/anomalylist/:id"
        element={<AnomalyListPage token={token} />}
      />
      <Route path="/alerts/:id" element={<AlertList token={token} />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/reset-password/:id" element={<ForgotPassword />} />
      <Route exact path="/set-password/:id" element={<SetPassword />} />
      <Route exact path="/reset-password" element={<ResetPasswordRequest />} />
      <Route
        exact
        path="/verify/:id"
        element={<VerificationModal show={true} />}
      />
      <Route exact path="/dashboard" element={<Dashboard />} />
      <Route path="/organization/:id" element={<OrganizationOverviewPage />} />
      <Route exact path="/mobile-verify/:id" element={<MobileVerification />} />
      <Route
        exact
        path="/authentication-page/:id"
        element={<UserAuthentication />}
      />
    </Routes>
  );
}

export default AppRoutes;
