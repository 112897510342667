import api from '../interceptors/axios'

async function editUser(data) {
  const token = localStorage.getItem("token");
  try {
    const response = await api.put(`/api/user`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data || error.response?.statusText || "An error occurred"
    );
  }
}

export { editUser };
