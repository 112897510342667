import api from '../interceptors/axios'

async function deleteData(form_id, data_id) {
    const token = localStorage.getItem('token');
    try {
        const response = await api.delete(`/api/form/${form_id}/data/${data_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

export { deleteData };
