import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col, Dropdown } from "react-bootstrap";

import { addData } from "../../hooks/dataAdd.js";
import "./style.css";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";

const DataAddModal = ({
  show,
  onHide,
  formId,
  template,
  getFormInfo,
  updateMessage,
  formName,
}) => {
  const [data, setData] = useState(null);
  const [dependentFieldMap, setDependentFieldMap] = useState(null);
  const [masterFeildName, setMasterFeildName] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [errors, setErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // initial dropdown choice
    let temp = {};
    let previousData = {};
    if (formName === "Lab Test") {
      const retrievedData = localStorage.getItem("LastSubmittedData");
      const dataObject = JSON.parse(retrievedData);
      previousData = dataObject ? dataObject : {};
    }

    template?.map((field) => {
      if (field.name in previousData) {
        temp[field.name] = previousData[field.name];
        if (field.type === "master_choice") {
          if (!field?.isMultiselect) {
            setMasterFeildName(field.name);
            var dependentValues = field.dependent_values[temp[field.name]];
            for (const key in dependentValues) {
              if (Array.isArray(dependentValues[key])) {
                // If it's an array, add only the first element to newTemp
                temp[key] = dependentValues[key][0];
                findAndUpdateTemplateChoices(
                  template,
                  key,
                  dependentValues[key]
                );
              } else {
                // Otherwise, add the value as is
                temp[key] = dependentValues[key];
              }
            }
            // temp = {...temp, ...dependentValues}
            setDependentFieldMap(field.dependent_values);
          }
        }
      } else if (field.type === "choice") {
        temp[field.name] = field?.choices?.[0];
      } else if (field.type === "master_choice") {
        if (!field?.isMultiselect) {
          setMasterFeildName(field.name);
          temp[field.name] = field?.choices?.[0];
          var dependentValues = field.dependent_values[field?.choices?.[0]];
          for (const key in dependentValues) {
            if (Array.isArray(dependentValues[key])) {
              // If it's an array, add only the first element to newTemp
              temp[key] = dependentValues[key][0];
              findAndUpdateTemplateChoices(template, key, dependentValues[key]);
            } else {
              // Otherwise, add the value as is
              temp[key] = dependentValues[key];
            }
          }
          setDependentFieldMap(field.dependent_values);
        }
      }
    });

    setData(temp);
  }, [template]);

  const handleSubmit = (isChecked = true) => {
    const newErrors = {};
    template.forEach((field) => {
      if (field.required && !data[field.name]) {
        newErrors[field.name] = `${field.name} is required`;
      }

      // Trim spaces and validate camelCase format for "reason for failure" field
      if (field.name === "Reason for Failure" && data[field.name]) {
        // Trim spaces from start and end
        data[field.name] = data[field.name].trim();
      }

      if (field.type === "time" && !data[field.name]) {
        data[field.name] = undefined;
      }

      if (
        (field.type === "datetime" || field.type === "date") &&
        data[field.name]
      ) {
        if (field.type === "datetime") {
          data[field.name] = moment(data[field.name]).format(
            "YYYY-MM-DDTHH:mm"
          );
        }
        if (field.type === "date") {
          data[field.name] = moment(data[field.name]).format("YYYY-MM-DD");
        }
        // Check if the date is in the future
        if (new Date(data[field.name]) > new Date()) {
          newErrors[field.name] = `${field.name} cannot be in the future`;
        }

        const year = moment(data[field.name]).year(); // Extract the year from the date

        // If the time is in future, show error
        if (year < 1000 || year > 9999) {
          newErrors[field.name] = `${field.name} is not a valid year`;
        } else if (
          moment(data[field.name]).isValid() &&
          moment(data[field.name]).isAfter(moment())
        ) {
          newErrors[field.name] = `${field.name} cannot be in the future`;
        }
      }
    });

    // List of date fields to validate
    const dateFields = [
      "Time of sample collection",
      "Time of lab test",
      "Start Time",
      "End Time",
      "Shutdown Datetime",
      "Resolved Datetime",
    ];

    // Helper function to validate dates and check order
    const isValidDate = (date) => moment(date).isValid();
    const isSameDate = (date1, date2) => date1.toString() === date2.toString();
    const addError = (field, message) => {
      newErrors[field] = message;
    };

    // Validate that all date fields are in a valid format
    dateFields.forEach((field) => {
      if (data[field] && !isValidDate(data[field])) {
        addError(field, `${field} is not a valid date`);
      }
    });

    // Validate date order only if both dates are valid
    const validateDateOrder = (startField, endField, startLabel, endLabel) => {
      if (!newErrors[startField] && !newErrors[endField]) {
        const startDate = new Date(data[startField]);
        const endDate = new Date(data[endField]);

        if (endDate < startDate) {
          addError(startField, `${startLabel} must be before ${endLabel}`);
        } else if (isSameDate(startDate, endDate)) {
          addError(
            startField,
            `${startLabel} and ${endLabel} cannot be the same`
          );
          addError(
            endField,
            `${endLabel} and ${startLabel} cannot be the same`
          );
        }
      }
    };

    // Perform date order validations only if initial format checks passed
    if (data["Time of sample collection"] && data["Time of lab test"]) {
      validateDateOrder(
        "Time of sample collection",
        "Time of lab test",
        "Time of sample collection",
        "Time of lab test"
      );
    }

    if (data["Start Time"] && data["End Time"]) {
      validateDateOrder("Start Time", "End Time", "Start Time", "End Time");
    }

    if (data["Shutdown Datetime"] && data["Resolved Datetime"]) {
      validateDateOrder(
        "Shutdown Datetime",
        "Resolved Datetime",
        "Shutdown Datetime",
        "Resolved Datetime"
      );
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    let newData = { ...data };
    if (data["Shutdown Datetime"] && data["Resolved Datetime"]) {
      newData = { ...data, Status: "Resolved" };
    }
    if (!isChecked) {
      newData = { ...data, isCheck: false };
    }
    if (newData["Override Reason"]) {
      delete newData["Override Reason"];
    }

    if (newData["Override Value"]) {
      delete newData["Override Value"];
    }
    setIsSubmitting(true);
    addData(newData, formId)
      .then((response) => {
        // Close the modal
        setShowConfirmation(false);
        if (response && response.isThreshold) {
          setShowConfirmation(true);
          setConfirmMessage(response.message);
        } else {
          handleHide();
          getFormInfo();
          if (formName === "Lab Test") {
            localStorage.setItem("LastSubmittedData", JSON.stringify(newData));
          }
          if (response && response.isWarning) {
            if (response.message && response.message.length > 0) {
              const messageList = `<ul>${response.message
                .map((mess) => `<li>${mess}</li><br>`)
                .join("")}</ul>`;
              updateMessage("warning", messageList, {
                html: true,
                requireConfirmation: true,
              });
            }
          } else {
            updateMessage("success", response.message);
          }
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        updateMessage("error", error.message);
        setIsSubmitting(false);
      });
  };

  const handleConfirmation = () => {
    handleSubmit(false);
  };

  const handleCheckboxChange = (event, choice, field_name) => {
    const selectedOptions = data?.[field_name] || [];

    if (choice == "Other - Please Specify" && event.target.checked) {
      setData((prevState) => ({
        ...prevState,
        [field_name]: [choice],
      }));
      return;
    }

    if (event.target.checked) {
      // Add the checked value to the array
      setData((prevState) => ({
        ...prevState,
        [field_name]: [...selectedOptions, choice],
      }));
    } else {
      // Remove the unchecked value from the array
      setData((prevState) => ({
        ...prevState,
        [field_name]: selectedOptions.filter((option) => option !== choice),
      }));
    }
  };

  const handleUpdate = (e, field, type) => {
    if (type === "Other Issue/More Details")
      if (type === "datetime" || type === "date") {
        const value = e.target.value;

        // Check if the input is in the format of `YYYY-MM-DDTHH:mm`
        const yearPart = value.split("-")[0];
        if (yearPart.length > 4) {
          // Trim the year to 4 digits if it exceeds 4 digits
          const trimmedValue = value.replace(/^(\d{4})\d*/, "$1");
          e.target.value = trimmedValue;
        }
      }
    setData((prevValues) => {
      // Check if the updated field is the masterField
      setErrors({
        ...errors,
        [field]: "",
      });
      if (field === masterFeildName) {
        const newValues = dependentFieldMap[e.target.value];
        const temp = {};
        for (const key in newValues) {
          if (Array.isArray(newValues[key])) {
            // If it's an array, add only the first element to newTemp
            temp[key] = newValues[key][0];
            findAndUpdateTemplateChoices(template, key, newValues[key]);
          } else {
            // Otherwise, add the value as is
            temp[key] = newValues[key];
          }
        }
        return {
          ...prevValues,
          ...temp,
          [field]: e.target.value,
        };
      }
      // If not the masterField, just update the specific field
      return {
        ...prevValues,
        [field]: e.target.value,
      };
    });
  };

  const findAndUpdateTemplateChoices = (template, fieldToChange, choices) => {
    template.forEach((field) => {
      if (field.name === fieldToChange) {
        field.choices = choices;
      }
    });
  };

  const handlePosition = (field) => {
    if (userPosition) {
      setData((prevValues) => {
        return { ...prevValues, [field]: userPosition };
      });
    } else {
      console.log("Geolocation is not available");
    }
  };

  // render each field by type
  const renderFields = (field) => {
    if (data) {
      switch (field.type) {
        case "datetime":
          return (
            <Form.Group
              key={field.name}
              controlId={field.name}
              className="modal-fld"
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld">
                <Form.Control
                  type="datetime-local"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                  format="YYYY-MM-DDThh:mm" // Format to yyyy-mm-ddThh:mm
                  max="9999-12-31T23:59"
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );
        case "decimal":
          return field.name === "Override Value" ? null : (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              <div className="txx-fld">
                <Form.Control
                  type="number"
                  step="0.01"
                  value={data[field.name]}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^-?\d{0,10}(\.\d{0,2})?$/;
                    if (regex.test(input) || input === "") {
                      handleUpdate(e, field.name, field.type); // Only update valid values
                    }
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+"].includes(e.key)) {
                      e.preventDefault();
                    }
                    if (field.name !== "Dew Point (C)" && e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "date":
          return (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              <div className="txx-fld">
                <Form.Control
                  type="date"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                  format="YYYY-MM-DD" // Format to yyyy-mm-dd
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "choice":
          return field.name === "Override Reason" ? null : (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              <div className="txx-fld as-select-input">
                <Form.Control
                  as="select"
                  multiple={
                    template.formName === "Equipment Downtime" &&
                    field.name === "Equipment Type"
                      ? true
                      : false
                  }
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                >
                  {field.choices?.map((choice, index) => (
                    <option key={index} value={choice}>
                      {choice}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "master_choice":
          return field?.isMultiselect ? (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld master-chioce-box">
                {/* Custom Dropdown with checkboxes styled like a full-width select component */}
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    className="w-100 d-flex justify-content-between align-items-center"
                    style={{
                      textAlign: "left",
                      borderColor: "#ced4da",
                      padding: "8px 12px",
                      borderRadius: "4px",
                      boxShadow: "none",
                      overflowX: "scroll",
                    }}
                  >
                    {data?.[field.name]?.length > 0
                      ? `Selected: ${data?.[field.name]?.join(", ")}`
                      : "Select options"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="w-100"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {field.choices.map((choice, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={choice}
                        value={choice}
                        checked={data?.[field.name]?.includes(choice)}
                        onChange={(e) =>
                          handleCheckboxChange(e, choice, field.name)
                        }
                        className="mx-3"
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          ) : (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <div className="txx-fld master-chioce-box">
                <Form.Control
                  as="select"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                >
                  {field.choices?.map((choice, index) => (
                    <option key={index} value={choice}>
                      {choice}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "text":
          if (field.name === "GPS coordinates of the farm") {
            return (
              <Form.Group
                className="modal-fld"
                key={field.name}
                controlId={field.name}
              >
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>

                <div className="txx-fld">
                  <Form.Control
                    value={data[field.name]}
                    onChange={(e) => handleUpdate(e, field.name, field.type)}
                    required={field.required}
                    isInvalid={!!errors[field.name]}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={(e) => handlePosition(field.name)}
                  >
                    Get my GPS
                  </Button>
                </div>
              </Form.Group>
            );
          } else {
            return (
              <Form.Group
                className="modal-fld"
                key={field.name}
                controlId={field.name}
              >
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>

                <div className="txx-fld">
                  <Form.Control
                    value={data[field.name]}
                    onChange={(e) => handleUpdate(e, field.name, field.type)}
                    required={field.required}
                    isInvalid={!!errors[field.name]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[field.name]}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            );
          }

        case "text-large":
          return (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              <div className="txx-fld">
                <Form.Control
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "boolean":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Check
                type="checkbox"
                value={data[field.name]}
                label={field.name}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "time":
          return (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              <div className="txx-fld">
                <Form.Control
                  type="time"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "master_value":
          return (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              <div className="txx-fld">
                <Form.Control
                  disabled={true}
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        case "master_value_choice":
          return (
            <Form.Group
              className="modal-fld"
              key={field.name}
              controlId={field.name}
            >
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              <div className="txx-fld as-select-input">
                <Form.Control
                  as="select"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                >
                  {field.choices?.map((choice, index) => (
                    <option key={index} value={choice}>
                      {choice}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          );

        default:
          return null;
      }
    }
  };

  // Hide the modal and reset the data
  const handleHide = () => {
    setShowConfirmation(false);
    template.forEach((field) => {
      data[field.name] = "";
      if (
        (field.type === "master_choice" && !field.isMultiselect) ||
        field.type === "master_value_choice" ||
        field.type === "choice"
      ) {
        data[field.name] = field?.choices?.[0];
      }
      if (field.type === "master_choice" && field.isMultiselect) {
        data[field.name] = [];
      }
    });
    setErrors({});
    onHide();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleHide}
        centered
        className={showConfirmation ? "confirm-popup" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {template && template?.map((field) => renderFields(field))}
          </Form>

          <Button
            variant="primary"
            className="save-btn"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Create
          </Button>
        </Modal.Body>
      </Modal>
      {showConfirmation && (
        <Modal show={showConfirmation} onHide={handleHide} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{confirmMessage}</p>

            <Button
              variant="primary"
              className="save-btn"
              onClick={handleConfirmation}
            >
              OK
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default DataAddModal;
