import api from '../interceptors/axios'

async function verifyMobile(data) {
    try {
        const response = await api.post(`/api/verifymobile`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data || error.response?.statusText || 'An error occurred');
    }
}

export { verifyMobile };
