import React from 'react'

import AppNavBar from '../../components/navbar'

import './style.css'

export const Terms = () => {
    return (
        <>
            <AppNavBar />
            <div className='term-container'>
                <h1>Ensaras Inc. Terms and Conditions</h1>
                <p>Effective Date: May 01, 2024</p>
                <h2>Acceptance of Terms</h2>
                <p>These terms and conditions govern the Service provided by Ensaras Inc. ("Ensaras" or "Provider"). As a User, your access to and/or use and continued use of the Ensaras AI Wastewater Stack Service ("Service") reflects your intent and agreement to comply with and be bound by these Terms and Conditions of Use ("Terms"). If you are not in agreement with these Terms, please immediately discontinue your use of the Service.</p>
                
                <h2>Definitions</h2>
                <ul>
                    <li>“Service” refers to Ensaras AI Wastewater Stack, including all associated websites, applications (web-based and/or software), and all supporting proprietary content.</li>
                    <li>“User” refers to any individual or entity that uses the Service.</li>
                    <li>“Provider” refers to Ensaras, the company providing the Service.</li>
                    <li>“Force Majeure Event” means an act of nature, fire, flood, war, civil unrest, embargo, cybersecurity incident, pandemic, or any other cause which is beyond the reasonable control of Ensaras and materially and adversely prohibits Ensaras from performing under these Terms or any related agreement.</li>
                </ul>
                
                <h2>License and Use of the Service</h2>
                <p>Subject to these Terms, the Provider grants User a limited, non-exclusive, non-transferable, and revocable license to use the Service for business purposes.</p>
                <p>User may:</p>
                <ul>
                    <li>Utilize the Service’s dashboard for reference.</li>
                    <li>Utilize the Service’s real-time AI analytics for reference.</li>
                    <li>Utilize the Service for scheduling of any relevant notices and alerts to streamline and optimize your business performance.</li>
                </ul>
                <p>User may not:</p>
                <ul>
                    <li>Modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell software, products, or services obtained from the Service.</li>
                    <li>Use the Service for any illegal or unauthorized purpose.</li>
                    <li>Remove or modify any copyright, trademark, or other proprietary rights notice contained in or on the Service.</li>
                </ul>
                
                <h2>Registration of User Accounts</h2>
                <p>The Service requires Users to have user accounts. Each User is responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.</p>
                <p>User agrees to:</p>
                <ul>
                    <li>Keep their login credentials secure and not share them with others.</li>
                    <li>Notify the Provider immediately of any unauthorized use of their account.</li>
                </ul>
                
                <h2>Privacy</h2>
                <h3>Information We Collect:</h3>
                <ul>
                    <li><strong>Personal Information:</strong> We collect personal information such as your name, email address, and contact details when you sign up for our Service or communicate with us.</li>
                    <li><strong>Automated Usage Information:</strong> Utilizing hardware or certificates installed on your entity’s plant, we collect information about how you interact with our SaaS platform, including your usage patterns, IP address, and device information.</li>
                    <li><strong>Plant Data:</strong> Utilizing automated data pipelines, we may collect data from the plant’s PLC, HMI, SCADA, IP cameras, and other databases related to plant operations (e.g., weigh bridges, laboratory systems, etc.) and utilizing data Users manually enter through our smart web forms and surveys, we collect data related to the plant’s performance and obtain User feedback, including but not limited to lab results, equipment health, equipment maintenance, feedstock data, power data, and feedback to improve our Service.</li>
                </ul>
                
                <h3>How We Use Your Information:</h3>
                <ul>
                    <li><strong>Providing Services:</strong> To provide, maintain, and improve the functionality of our SaaS platform and associated Services, deliver customer support, and respond to your requests.</li>
                    <li><strong>Communication:</strong> To send you important updates, alerts, newsletters, and marketing materials related to our Services. You can opt-out of any form of communication by emailing <a href="mailto:info@ensaras.com">info@ensaras.com</a>.</li>
                    <li><strong>Billing:</strong> If on a billing module, to process payments and invoices for our Service.</li>
                </ul>
                
                <h3>Data Security:</h3>
                <p>We take the security of your data seriously and implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>
                
                <h3>Sharing Your Information:</h3>
                <p>We do not sell, trade, or rent your information to third parties. However, we reserve the right to use your data for purposes of providing our Service. We also reserve the right to use anonymized data for promoting and marketing our Service and publishing the benefits of our Service.</p>
                
                <h2>Termination</h2>
                <p>The Provider reserves the right to terminate or suspend your access to the Service at its discretion, with or without cause and without notice. You may terminate your account at any time by discontinuing use of the Service.</p>
                
                <h2>Intellectual Property</h2>
                <p>All intellectual property rights in the Service, including but not limited to copyrights, trademarks, trade secrets, logos, our developed and proprietary algorithms, models, code, software, and patents, are owned and shall remain solely owned by Ensaras or its respective licensors. Nothing in these Terms or in any related agreement or statement of work ("SOW") shall be construed as granting the User, its affiliates, its employees or related parties expressly by implication, estoppel or otherwise, any license under or any right to utilize: (a) any proprietary information received from any other party hereto except the limited right to use such information for performing obligations with respect to the Service, any agreement or SOW; or (b) any patent, trademark or copyright now or hereafter owned or controlled by Ensaras.</p>
                
                <h2>Confidential Information</h2>
                <p>“Confidential Information” means all technical, commercial information, know-how, specifications, processes, formulae, initiatives, algorithms, trade secrets or any information contained in or required for provision of the Service by Ensaras.  
                    User agrees to keep confidential all such Confidential Information which they receive from Ensaras in the course of utilizing the Service. User agrees to take all reasonable steps to protect the Confidential Information and prevent its disclosure or use contrary to the obligation set forth in this paragraph. 
                    These obligations of confidentiality shall not apply to Confidential Information that enters the public domain and becomes public knowledge through no fault of the receiving party. 
                    It is accepted by the User that damages alone may not be an adequate remedy for breach of this confidentiality undertaking and accordingly in such circumstances the aggrieved party would be entitled to seek injunction or similar relief. </p>
                
                <h2>Disclaimers</h2>
                <p>The Service is provided “as is”, “where is”, and “as available” without warranties or any kind, either express or implied. The Provider disclaims all warranties, including but not limited to general and specific warranties of any kind, any and all implied warranties of merchantability or warranties for fitness for a particular purpose.</p>
                <p>Notwithstanding the above, Ensaras warrants that it shall obtain and at all times maintain all necessary licenses and consents, and comply with all applicable laws and regulations.</p>

                <h2>Limitation of Liability</h2>
                <p>To the fullest extent permitted by law, the Provider shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:</p>
                <ul>
                    <li>Your use of or inability to use the Service.</li>
                    <li>Any unauthorized access to or use of our servers and/or any personal or business information stored therein.</li>
                    <li>Any interruption or cessation of transmission to or from the Service.</li>
                    <li>Any decisions made or actions taken as a result of using the Service.</li>
                </ul>
                
                <h2>Choice of Law/Venue</h2>
                <p>These Terms are governed by and construed in accordance with the substantive laws of the State of Illinois without regard to conflict of laws. All disputes arising under or relating to these Terms shall be brought in and resolved solely and exclusively by the Federal and State Courts in the State of Illinois.</p>
                
                <h2>Amendment to the Terms</h2>
                <p>The Provider may modify or amend these Terms at any time. You will be notified of any material changes in the Terms and Conditions as it relates to your SOW. Any modifications or amendments will be effective upon posting the revised Terms on the Service portal/Service application. Your continued use of the Service after such posted modifications or amendments shall constitute your acceptance of the updated Terms.</p>
                
                <h2>Anti-Bribery and Corruption</h2>
                <p>Each party shall fully comply with all applicable federal, state and local laws, rules and regulations.  Each party hereby warrants and represents that it is familiar with anti-bribery and corruption legislation applicable in the country where the services are being provided (“Anti-Bribery Laws”) and that it is aware that Anti-Bribery Laws prohibit, under the threat of severe penalty, the payment or giving of anything of value, either directly or indirectly through other parties, to an official of a foreign government (including officers and managers of government-owned or controlled companies) for the purpose of influencing these persons in their official capacity or inducing them to use their influence to assist the other party or its affiliates in obtaining or retaining business with any person.  Each party agrees that no part of any funds paid to it by the other party will be used for any purpose which violates the Anti-Bribery Laws including those applicable in any jurisdiction in which these services are performed.</p>

                <h2>Notices</h2>
                <p>Any required notices that shall be required to be sent or served by Client or Beta Tester shall be emailed to the following:  <a href="mailto:info@ensaras.com">info@ensaras.com</a></p>
                <p>Any required notices that shall be required to be sent or served by Ensaras shall be emailed to the contact designated by the User in the SOW.</p>

                <h2>Force Majeure</h2>
                <p>If a Force Majeure Event materially and adversely affects Ensaras’ ability to perform its obligations under these Terms or any related agreement or SOW, and the Force Majeure Event continues for more than thirty (30) days, User may terminate this Agreement and/or the related and applicable agreements and/or SOW’s immediately upon notice to Ensaras.</p>

                <h2>Contact Information</h2>
                <p>Please direct any concerns or questions you may have regarding these Terms to: <a href="mailto:info@ensaras.com">info@ensaras.com</a>.</p>

                <p><b>Thank you for support of Ensaras.  We look forward to working with you.</b></p>
            </div>
        </>        
    )
}