import axios from "axios";
import config from '../config';
const api = axios.create({
    baseURL: `${config.apiUrl}`, // Use the appropriate base URL from your config
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            const { message } = error.response.data;
            if (message === "Unauthorized") {
                localStorage.clear();
                window.location.href = '/signin';
            }
            throw new Error(error.response?.data || 'An error occurred');
        }
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/signin';
        }
        return Promise.reject(error);
    }
);

export default api
