import React, { useState, useEffect } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {Row, Col, Table, Button } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { AiOutlineDelete } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";

import AppNavBar from "../../components/navbar";
import AppFooter from "../../components/footer";

import DataAddModal from "../../components/dataAdd";
import DataEditModal from "../../components/dataEdit";
import DataDeleteModal from "../../components/dataDelete";
import MessagePopup from "../../components/messagePopup";

import { getFormData } from "../../hooks/form";
import { downloadFormData } from "../../hooks/dataDownload";
import previous from "../../assets/pagination-left.svg";
import next from "../../assets/pagination-right.svg";
import "./style.css";
import moment from "moment-timezone";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";

export const FormPage = (token) => {
  const { id } = useParams();
  const [orgId, setOrgId] = useState(null);
  const location = useLocation();
  const activeTab = localStorage.getItem("activeTab");
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orgIdFromQuery = queryParams.get("orgId");
    setOrgId(orgIdFromQuery);
  }, [location]);
  // message window content
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [requireConfirmation, setRequireConfirmation] = useState(false);

  const updateMessage = (type, content, options = {}) => {
    setMessageType(type);
    if (options && options.html) {
      setMessage(<div dangerouslySetInnerHTML={{ __html: content }} />);
    } else {
      setMessage(content);
    }
    setRequireConfirmation(options.requireConfirmation || false);
  };

  // response from backend
  const [formName, setFormName] = useState("");
  const [template, setTemplate] = useState([]);
  const [formData, setFormData] = useState([]);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canAdd, setCanAdd] = useState(true);
  const [teamName, setTeamName] = useState("");
  const [downloadOptions, setDownloadOptions] = useState([]);

  const filterFormNames = ["Equipment Downtime", "Lab Test"];
  const labTestFormName = "Lab Test";
  const masterFormName = "Master Data Management";
  const equipmentDowntimeFormName = "Equipment Downtime";

  const [downloadData, setDownloadData] = useState("");
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  // lab test type or equipment maintenance equipment
  const [equipments, setEquipments] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);

  // lab test location
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  // team filter
  const [teamNames, setTeamNames] = useState([]);
  const [selectedTeamNames, setSelectedTeamNames] = useState([]);

  // status filter
  const [statuses, setStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  // filter of the var equipments
  const [showFilter, setShowFilter] = useState(false);
  // filter of the var locations
  const [showLocFilter, setShowLocFilter] = useState(false);
  // filter of the teams
  const [showTeamFilter, setShowTeamFilter] = useState(false);
  // filter of the status
  const [showStatusFilter, setShowStatusFilter] = useState(false);

  // form pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(1);
  const handlePageChange = (value) => {
    const page = parseInt(value, 10);
    if (!isNaN(page) && page >= 1 && page <= pageCount) {
      setPage(page);
    }
  };
  const navigate = useNavigate();

  const getFormInfo = () => {
    getFormData(id, { range: timeRange, page: page, pageSize: pageSize || 20 })
      .then((response) => {
        if (response.message !== "Success") {
          updateMessage("error", response.message);
          setFormData([]);
          return {};
        }
        // store the info
        setFormName(response.data.formName);
        setTemplate(response.data.template);
        setFormData(response.data.data);
        setCanEdit(response.data.canEdit);
        setCanDelete(response.data.canDelete);
        setCanAdd(response.data?.canAdd);
        setPageCount(response.data.pageCount);
        setTeamName(response.data.teamName);
        const dateFilterOptions = response.data.downloadOptions?.map(
          (option) => ({
            value: option.value,
            label: option.description,
          })
        );
        setDownloadOptions(dateFilterOptions);

        // check whether to show the filters
        setShowFilter(filterFormNames.includes(response.data.formName));
        setShowLocFilter(response.data.formName === labTestFormName);
        setShowTeamFilter(response.data.teamName === masterFormName);
        setShowStatusFilter(
          response.data.formName === equipmentDowntimeFormName
        );

        // set for the var equipments
        if (filterFormNames.includes(response.data.formName)) {
          setEquipments(
            response.data.template?.[0]["choices"]?.map((item) => {
              return { label: item, value: item };
            })
          );
          setSelectedEquipments(
            response.data.template?.[0]["choices"]?.map((item) => {
              return { label: item, value: item };
            })
          );
        }

        // set for the var locations
        if (response.data.formName === labTestFormName) {
          setLocations(
            response.data.template[1]["choices"]?.map((item) => {
              return { label: item, value: item };
            })
          );
          setSelectedLocations(
            response.data.template[1]["choices"]?.map((item) => {
              return { label: item, value: item };
            })
          );
        }

        if (response.data.teamName === masterFormName) {
          // setTimeRange("all");
          setTeamNames(
            response.data.template?.[0]["choices"]?.map((item) => {
              return { label: item, value: item };
            })
          );
          if (response?.data?.formName !== "Equipment Issues Master") {
            setSelectedTeamNames(
              response.data.template?.[0]["choices"]?.map((item) => {
                return { label: item, value: item };
              })
            );
          }
        }

        if (response.data.formName === equipmentDowntimeFormName) {
          setStatuses(
            response.data.template?.[2]["choices"]?.map((item) => {
              return { label: item, value: item };
            })
          );
          setSelectedStatuses(
            response.data.template?.[2]["choices"]?.map((item) => {
              return { label: item, value: item };
            })
          );
        }
      })
      .catch((error) => {
        updateMessage("error", "Failed to get the form data!");
      });
  };
  useEffect(() => {
    setUserInfo({
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
      userId: localStorage.getItem("userId"),
      role: localStorage.getItem("role"),
    });
    getFormInfo();
  }, []);

  // change the layout by page width
  const [isWideEnough, setIsWideEnough] = useState(window.innerWidth > 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsWideEnough(window.innerWidth > 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handle data create modal
  const [showDataAddModal, setShowDataAddModal] = useState(false);
  const handleDataAddModal = (status) => {
    setShowDataAddModal(status);
  };

  // handle data edit/delete modal
  const [dataInfoToModify, setDataInfoToModify] = useState(null);

  const [showDataEditModal, setShowDataEditModal] = useState(false);
  const handleDataEditModal = (status, dataInfo) => {
    if (dataInfo && dataInfo['Time of Data Entry'])
      dataInfo['Time of Data Entry'] = timeAndDateFormatToISO8601(
        dataInfo['Time of Data Entry']
      );
    if (status) {
      // pass the info to modal
      setDataInfoToModify(dataInfo);
      setShowDataEditModal(status);
    } else {
      setDataInfoToModify(null);
      setShowDataEditModal(status);
    }
  };

  const formatFieldData = (data, template) => {
    const formattedData = { ...data };
  
    template.forEach((field) => {
      if (field.type === "date" && formattedData[field.name]) {
        formattedData[field.name] = dateFormatter(formattedData[field.name]);
      } else if (field.type === "datetime" && formattedData[field.name]) {
        formattedData[field.name] = timeFormatter(formattedData[field.name]);
      }
    });
  
    return formattedData;
  };

  const [showDataDeleteModal, setShowDataDeleteModal] = useState(false);
  const handleDataDeleteModal = (status, dataInfo) => {
    if (status) {
      if (dataInfo && dataInfo['Time of Data Entry']) {
        // Convert the time to the desired 12-hour format
        dataInfo['Time of Data Entry'] = moment(dataInfo['Time of Data Entry']).format(
          "MMM DD, YYYY, hh:mm A"
        );
      }
      const formattedData = formatFieldData(dataInfo, template);
      // pass the info to modal
      setDataInfoToModify(formattedData);
      setShowDataDeleteModal(status);
    } else {
      setDataInfoToModify(null);
      setShowDataDeleteModal(status);
    }
  };

  // timerange dropdown
  const [timeRange, setTimeRange] = useState("30d");
  const handleLocationSelection = (value) => {
    setSelectedLocations(value);
  };
  const handleEquipmentSelection = (value) => {
    setSelectedEquipments(value);
  };
  const handleTeamSelection = (value) => {
    setSelectedTeamNames(value);
  };
  const handleStatusSelection = (value) => {
    setSelectedStatuses(value);
  };

  // update data when filters changes
  useEffect(() => {
    const labels = selectedEquipments?.map((item) => item["label"]);
    const locLabels = selectedLocations?.map((item) => item["label"]);
    const teams = selectedTeamNames?.map((item) => item["label"]);
    const statuses = selectedStatuses?.map((item) => item["label"]);
    getFormData(id, {
      range: timeRange,
      page: page,
      pageSize: pageSize || 20,
      locations: locLabels,
      equipments: labels,
      teams: teams,
      statuses: statuses,
    })
      .then((response) => {
        if (response.message !== "Success") {
          updateMessage("error", response.message);
          setFormData(() => []);
          return {};
        }
        // store the info
        setFormData(response.data.data);
        setPageCount(response.data.pageCount);
      })
      .catch((error) => {
      });
  }, [timeRange, page, pageSize, selectedEquipments]);

  const applyFilterChanges = () => {
    const labels = selectedEquipments?.map((item) => item["label"]);
    const locLabels = selectedLocations?.map((item) => item["label"]);
    const teams = selectedTeamNames?.map((item) => item["label"]);
    const statuses = selectedStatuses?.map((item) => item["label"]);
    getFormData(id, {
      range: timeRange,
      page: page,
      pageSize: pageSize || 20,
      locations: locLabels,
      equipments: labels,
      teams: teams,
      statuses: statuses,
    })
      .then((response) => {
        if (response.message !== "Success") {
          updateMessage("error", response.message);
          setFormData([]);
          return {};
        }
        // store the info
        setFormData(response.data.data);
        setPageCount(response.data.pageCount);
      })
      .catch((error) => {
      });
  };

  const handleRef = (link) => {
    if (link && downloadData && !loading) {
      link.link.click(); // Trigger the CSV download
    }
    setDownloadData("");
  };

  const handleDownloadClick = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      setDownloadData("");
      const labels = selectedEquipments?.map((item) => item["label"]);
      const locLabels = selectedLocations?.map((item) => item["label"]);
      const teams = selectedTeamNames?.map((item) => item["label"]);
      const statuses = selectedStatuses?.map((item) => item["label"]);
      if (id !== 163) {
        downloadFormData(id, {
          range: timeRange,
          locations: locLabels,
          equipments: labels,
          teams: teams,
          statuses: statuses,
        })
          .then((response) => {
            // store the info
            if (typeof response == "string") {
              response = response.replace(/\bNaN\b/g, "null");
              response = JSON.parse(response);
            }
            const outputData = JSON.parse(JSON.stringify(response.data.data));
            setDownloadData(outputData);
            // setCsvLink(null);
            setLoading(false);
          })
          .catch((error) => {
            updateMessage("error", "Failed to prepare for data downloading!");
            setLoading(false);
          });
      }
    }
  }, [loading]);

  // date/time formatter
  const timeFormatter = (time) => {
    if (!time) return "";
    const date = new Date(time);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  const dateFormatter = (dateString) => {
    if (!dateString) return "";
    dateString += "T12:00:00";
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const timeAndDateFormatToISO8601 = (time) => {
    return moment(time, "MMM. DD, YYYY, hh:mm A").isValid()
      ? moment(time, "MMM. DD, YYYY, hh:mm A").format("YYYY-MM-DDTHH:mm:ss")
      : time;
  };

  const renderTooltip = (props, fullText) => (
    <Tooltip id="button-tooltip" {...props}>
      {fullText}
    </Tooltip>
  );

  if (isWideEnough) {
    return (
      <>
        <AppNavBar />
        <div className="form-table-ctn form-listing-sec">
          <div className="table-title-ctn">
            <div
              onClick={() =>
                navigate(`/organization/${orgId}?activeTab=${activeTab}`)
              }
              className="list-arrow"
            >
              <FaArrowLeft />
            </div>
            <h2 className="table-title">
              {formName} <span>( {teamName} ) </span>
            </h2>
            {canAdd && (
              <Button
                variant="primary"
                size="sm"
                className="add-btn"
                onClick={handleDataAddModal}
              >
                Add
              </Button>
            )}
          </div>

          <div className="tool-container mb-2">
            <div className="half-tool-container">
              {teamName &&
                teamName !== masterFormName &&
                !["Equipment Downtime", "Farmer Survey"].includes(formName) && (
                  <div className="left-team-nm date-filter">
                    <label className="tool-name">
                      Range of{" "}
                      {formName === "Equipment Maintenance"
                        ? "Start Date"
                        : "Time of Data Entry"}
                      :
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      aria-label="Date Filter Dropdown"
                      value={
                        downloadOptions?.find(
                          (type) => type.value === timeRange
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        setTimeRange(selectedOption?.value)
                      }
                      options={downloadOptions}
                      placeholder="Select Alert Type"
                    />
                  </div>
                )}

              {(userInfo.role === "Ensaras Admin" ||
                userInfo.role === "Client Admin") && (
                <div>
                  <button
                    className="download-btn"
                    onClick={handleDownloadClick}
                    disabled={loading}
                  >
                    <IoCloudDownloadOutline />
                    {loading ? "Loading..." : "Download"}
                  </button>
                  {downloadData && (
                    <CSVLink
                      data={downloadData}
                      filename="download.csv"
                      ref={handleRef}
                      target="_blank"
                      style={{ display: "none" }}
                    />
                  )}
                </div>
              )}
            </div>

            <div className="half-tool-container">
              {showLocFilter && (
                <MultiSelect
                  options={locations}
                  value={selectedLocations}
                  onChange={handleLocationSelection}
                  labelledBy="Select"
                  className="mutliselect"
                  overrideStrings={{
                    allItemsAreSelected: "All Locations",
                  }}
                />
              )}

              {showFilter && (
                <MultiSelect
                  options={equipments}
                  value={selectedEquipments}
                  onChange={handleEquipmentSelection}
                  labelledBy="Select"
                  className="mutliselect"
                  overrideStrings={{
                    allItemsAreSelected: "All Parameters",
                  }}
                />
              )}

              {showTeamFilter && (
                <MultiSelect
                  options={teamNames}
                  value={selectedTeamNames}
                  onChange={handleTeamSelection}
                  labelledBy="Select"
                  className="mutliselect"
                  overrideStrings={{
                    allItemsAreSelected: "All Teams",
                  }}
                />
              )}

              {showStatusFilter && (
                <MultiSelect
                  options={statuses}
                  value={selectedStatuses}
                  onChange={handleStatusSelection}
                  labelledBy="Select"
                  className="mutliselect"
                  overrideStrings={{
                    allItemsAreSelected: "All Statuses",
                  }}
                />
              )}
              {(showLocFilter ||
                showFilter ||
                showTeamFilter ||
                showStatusFilter) && (
                <button className="apply-btn" onClick={applyFilterChanges}>
                  Apply
                </button>
              )}
            </div>
          </div>

          {/* <Container fluid> */}
          <Row>
            <Col>
              <div className="table-responsive">
                <Table responsive bordered className="alert-table">
                  <thead className="table-dark">
                    <tr>
                      <th className="col-names text-center">
                        Time of Data Entry
                      </th>
                      <th className="col-names text-center">User</th>
                      {template?.map((data) => {
                        if (
                          data.name === "Please Check to override" ||
                          data.name === "Override Value"
                        ) {
                          return;
                        }
                        if (data.name !== "If Other, please specify") {
                          return (
                            <th className="col-names text-center">
                              {data.name}
                            </th>
                          );
                        }
                      })}
                      {canEdit && (
                        <th className="col-names text-center">Edit</th>
                      )}
                      {canDelete && (
                        <th className="col-names text-center">Delete</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.map((data) => (
                      <tr>
                        <td className="text-center">
                          {timeFormatter(data['Time of Data Entry'])}
                        </td>
                        <td className="text-center">{data.user}</td>
                        {template?.map((field) => {
                          if (
                            field.name === "Please Check to override" ||
                            field.name === "Override Value"
                          ) {
                            return;
                          } else if (
                            field.name === "Equipment" &&
                            data[field.name] === "Other - Please Specify"
                          ) {
                            return (
                              <td className="text-center">
                                {data["If Other, please specify"]}
                              </td>
                            );
                          } else if (field.type === "datetime") {
                            return (
                              <td className="text-center">
                                {timeFormatter(data[field.name])}
                              </td>
                            );
                          } else if (field.type === "date") {
                            return (
                              <td className="text-center">
                                {dateFormatter(data[field.name])}
                              </td>
                            );
                          } else if (field.name === "Result") {
                            return (
                              <td className="text-center">{`${data["Result"]} (${data["Unit"]})`}</td>
                            );
                          } else if (
                            field.name === "Daily Flow (M3)" ||
                            field.name === "Daily Power (KW)" || 
                            field.name === "Daily Flow (kg)"
                          ) {
                            return (
                              <td className="text-center">
                                {!data[field.name] ||
                                (data[field.name] <= 0 &&
                                  !data["Please Check to override"]) ? (
                                  "-"
                                ) : (
                                  <div>
                                    {data["Please Check to override"]
                                      ? `${data["Override Value"]} (Override)`
                                      : data[field.name]}
                                  </div>
                                )}
                              </td>
                            );
                          } else if (field.name === "Override Reason") {
                            return (
                              <td className="text-center">
                                {data["Please Check to override"]
                                  ? data[field.name]
                                  : ""}
                              </td>
                            );
                          } else if (
                            formName === "Equipment Downtime" &&
                            field.type === "master_choice" &&
                            field.isMultiselect === true
                          ) {
                            return (
                              <td className="text-center">
                                {data[field.name]?.join(", ")}
                              </td>
                            );
                          } else if (
                            field.name === "Notes" ||
                            field.name === "Other Issue/More Details"
                          ) {
                            return (
                              <td className="text-center">
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={(props) =>
                                    renderTooltip(props, data[field.name])
                                  }
                                >
                                  <div className="text-truncate">
                                    {data[field.name]}
                                  </div>
                                </OverlayTrigger>
                              </td>
                            );
                          } else if (
                            field.name !== "If Other, please specify"
                          ) {
                            return (
                              <td className="text-center">
                                {data[field.name]}
                              </td>
                            );
                          }
                        })}
                        {canEdit && (
                          <td className="text-center">
                            <button
                              className="table-btn"
                              onClick={() => handleDataEditModal(true, data)}
                            >
                              <FiEdit />
                            </button>
                          </td>
                        )}
                        {canDelete && (
                          <td className="text-center">
                            <button
                              className="table-btn"
                              onClick={() => handleDataDeleteModal(true, data)}
                            >
                              <AiOutlineDelete />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {formData.length === 0 && (
                  <div className="no-form-data"> Data not found</div>
                )}
              </div>
            </Col>
          </Row>
          {/* </Container> */}
          {formData && formData.length > 0 && (
            <div className="pagination-outer">
              <div>&nbsp; </div>
              <div className="paginations-inner">
                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  <img src={previous} alt="Previous" />
                </Button>
                <input
                  type="text"
                  className="current-page-input"
                  value={page}
                  onChange={(e) => {
                    const value = e.target.value;
                    const page = value === "" ? "" : parseInt(value, 10);
                    if (page === "" || (page >= 1 && page <= pageCount)) {
                      setPage(page);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const page = value === "" ? 1 : parseInt(value, 10);
                    if (page < 1 || page > pageCount) {
                      setPage(1);
                    } else {
                      setPage(page);
                    }
                  }}
                  min={1}
                  max={pageCount}
                />

                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === pageCount}
                >
                  <img src={next} alt="Next" />
                </Button>
                <span>of {pageCount} pages</span>
              </div>
              <div className="pagesize-ctn">
                <label htmlFor="pageSize">Showing</label>
                <input
                  type="text"
                  id="pageSize"
                  value={pageSize}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only positive integers
                    if (/^\d+$/.test(value)) {
                      const intValue = parseInt(value, 10);
                      if (intValue > 0) {
                        setPageSize(intValue);
                      }
                    } else if (value === "") {
                      setPageSize(""); // Allow clearing the input
                    }
                  }}
                  onBlur={(e) => {
                    // Set default page size if input is empty
                    if (e.target.value === "") {
                      setPageSize(20);
                    }
                  }}
                  pattern="\d+" // Allows only digits
                  title="Enter a positive number"
                  min={1}
                  className="pagesize-box"
                />

                <label htmlFor="pageSize">items per page</label>
              </div>
            </div>
          )}
        </div>

        <DataAddModal
          show={showDataAddModal}
          onHide={() => handleDataAddModal(false, null)}
          formId={id}
          template={template}
          getFormInfo={getFormInfo}
          updateMessage={updateMessage}
          formName={formName}
        />

        <DataEditModal
          show={showDataEditModal}
          onHide={() => handleDataEditModal(false, null)}
          dataInfo={dataInfoToModify}
          formId={id}
          template={template}
          getFormInfo={getFormInfo}
          updateMessage={updateMessage}
        />

        <DataDeleteModal
          show={showDataDeleteModal}
          onHide={() => handleDataDeleteModal(false, null)}
          dataInfo={dataInfoToModify}
          formId={id}
          formName={formName}
          getFormInfo={getFormInfo}
          updateMessage={updateMessage}
        />

        <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
          requireConfirmation={requireConfirmation}
        />

        <AppFooter />
      </>
    );
  } else {
    return (
      <>
        <AppNavBar
          needBack={true}
          backLink={`/organization/${orgId}?activeTab=${activeTab}`}
        />
        <div className="form-table-ctn">
          <div className="table-title mb-2">
            <h4>
              {formName} <span>({teamName})</span>
            </h4>
          </div>
          {canAdd && (
            <Button
              variant="primary"
              size="sm"
              className="add-btn-narrow"
              onClick={handleDataAddModal}
            >
              Add
            </Button>
          )}

          <div className="tool-container">
            {teamName &&
              teamName !== masterFormName &&
              !["Equipment Downtime", "Farmer Survey"].includes(formName) && (
                <div className="date-filter">
                  <label className="tool-name">
                    Range of{" "}
                    {formName === "Equipment Maintenance"
                      ? "Start Date"
                      : "Time of Data Entry"}
                    :
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    aria-label="Date Filter Dropdown"
                    value={
                      downloadOptions?.find(
                        (type) => type.value === timeRange
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      setTimeRange(selectedOption?.value)
                    }
                    options={downloadOptions}
                    placeholder="Select Alert Type"
                  />
                </div>
              )}
            {(userInfo.role === "Ensaras Admin" ||
              userInfo.role === "Client Admin") && (
              <div className="mobile-download">
                <button
                  className="download-btn"
                  onClick={handleDownloadClick}
                  disabled={loading}
                >
                  <IoCloudDownloadOutline />
                  {loading ? "Loading..." : "Download"}
                </button>
                {downloadData && (
                  <CSVLink
                    data={downloadData}
                    filename="download.csv"
                    ref={handleRef}
                    target="_blank"
                    style={{ display: "none" }}
                  />
                )}
              </div>
            )}
          </div>

          <div className="tool-container mobile-select">
            {showLocFilter && (
              <MultiSelect
                options={locations}
                value={selectedLocations}
                onChange={handleLocationSelection}
                labelledBy="Select"
                className="mutliselect"
                overrideStrings={{
                  allItemsAreSelected: "All Locations",
                }}
              />
            )}

            {showFilter && (
              <MultiSelect
                options={equipments}
                value={selectedEquipments}
                onChange={handleEquipmentSelection}
                labelledBy="Select"
                className="mutliselect"
                overrideStrings={{
                  allItemsAreSelected: "All Parameters",
                }}
              />
            )}

            {showTeamFilter && (
              <MultiSelect
                options={teamNames}
                value={selectedTeamNames}
                onChange={handleTeamSelection}
                labelledBy="Select"
                className="mutliselect"
                overrideStrings={{
                  allItemsAreSelected: "All Teams",
                }}
              />
            )}

            {showStatusFilter && (
              <MultiSelect
                options={statuses}
                value={selectedStatuses}
                onChange={handleStatusSelection}
                labelledBy="Select"
                className="mutliselect"
                overrideStrings={{
                  allItemsAreSelected: "All Statuses",
                }}
              />
            )}
            {(showLocFilter ||
              showFilter ||
              showTeamFilter ||
              showStatusFilter) && (
              <button className="mobile-apply" onClick={applyFilterChanges}>
                Apply
              </button>
            )}
          </div>

          {/* <Container fluid> */}
          {formData.length === 0 && (
            <div className="no-data">Data not found</div>
          )}
          {formData?.map((data, index) => (
            <Row
              key={index}
              className={`form-row ${
                index % 2 === 0 ? "row-even" : "row-odd"
              } mb-4`}
            >
              <Row>
                <Col className="field-names">Time of Data Entry</Col>
                <Col className="text-center">{data['Time of Data Entry']}</Col>
              </Row>
              <Row>
                <Col className="field-names">User</Col>
                <Col className="text-center">{data.user}</Col>
              </Row>
              {template?.map((field) => {
                if (
                  field.name === "If Other, please specify" ||
                  field.name === "Please Check to override" ||
                  field.name === "Override Value"
                ) {
                  return;
                } else if (
                  field.name === "Equipment" &&
                  data[field.name] === "Other - Please Specify"
                ) {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">
                        {data["If Other, please specify"]}
                      </Col>
                    </Row>
                  );
                } else if (field.type === "datetime") {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">
                        {timeFormatter(data[field.name])}
                      </Col>
                    </Row>
                  );
                } else if (field.type === "date") {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">
                        {dateFormatter(data[field.name])}
                      </Col>
                    </Row>
                  );
                } else if (field.name === "Result") {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">{`${data["Result"]} (${data["Unit"]})`}</Col>
                    </Row>
                  );
                } else if (
                  field.name === "Daily Flow (M3)" ||
                  field.name === "Daily Power (KW)" ||
                  field.name === "Daily Flow (kg)"
                ) {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">
                        {!data[field.name] ||
                        (data[field.name] <= 0 &&
                          !data["Please Check to override"]) ? (
                          "-"
                        ) : (
                          <div>
                            {data["Please Check to override"]
                              ? `${data["Override Value"]} (Override)`
                              : data[field.name]}
                          </div>
                        )}
                      </Col>
                    </Row>
                  );
                } else if (
                  formName == "Equipment Downtime" &&
                  field.type == "master_choice" &&
                  field.isMultiselect == true
                ) {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">
                        {data[field.name]?.join(", ")}
                      </Col>
                    </Row>
                  );
                } else if (
                  field.name === "Notes" ||
                  field.name === "Other Issue/More Details"
                ) {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip(props, data[field.name])
                          }
                        >
                          <div className="text-truncate">
                            {data[field.name]}
                          </div>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  );
                } else {
                  return (
                    <Row>
                      <Col className="field-names">{field.name}</Col>
                      <Col className="text-center">{data[field.name]}</Col>
                    </Row>
                  );
                }
              })}
              {canEdit && (
                <Row>
                  <Col className="field-names">Edit</Col>
                  <Col className="text-center">
                    <button
                      className="table-btn"
                      onClick={() => handleDataEditModal(true, data)}
                    >
                      <FiEdit />
                    </button>
                  </Col>
                </Row>
              )}
              {canDelete && (
                <Row>
                  <Col className="field-names">Delete</Col>
                  <Col className="text-center">
                    <button
                      className="table-btn"
                      onClick={() => handleDataDeleteModal(true, data)}
                    >
                      <AiOutlineDelete />
                    </button>
                  </Col>
                </Row>
              )}
            </Row>
          ))}
          {formData && formData.length > 0 && (
            <div className="pagination-outer">
              <div>&nbsp; </div>
              <div className="paginations-inner">
                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  <img src={previous} alt="Previous" />
                </Button>
                <input
                  type="text"
                  className="current-page-input"
                  value={page}
                  onChange={(e) => {
                    const value = e.target.value;
                    const page = value === "" ? "" : parseInt(value, 10);
                    if (page === "" || (page >= 1 && page <= pageCount)) {
                      setPage(page);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    const page = value === "" ? 1 : parseInt(value, 10);
                    if (page < 1 || page > pageCount) {
                      setPage(1);
                    } else {
                      setPage(page);
                    }
                  }}
                  min={1}
                  max={pageCount}
                />

                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === pageCount}
                >
                  <img src={next} alt="Next" />
                </Button>
                <span>of {pageCount} pages</span>
              </div>
              <div className="pagesize-ctn">
                <label htmlFor="pageSize">Showing</label>
                <input
                  type="text"
                  id="pageSize"
                  value={pageSize}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only positive integers
                    if (/^\d+$/.test(value)) {
                      const intValue = parseInt(value, 10);
                      if (intValue > 0) {
                        setPageSize(intValue);
                      }
                    } else if (value === "") {
                      setPageSize(""); // Allow clearing the input
                    }
                  }}
                  onBlur={(e) => {
                    // Set default page size if input is empty
                    if (e.target.value === "") {
                      setPageSize(20);
                    }
                  }}
                  pattern="\d+" // Allows only digits
                  title="Enter a positive number"
                  min={1}
                  className="pagesize-box"
                />

                <label htmlFor="pageSize">items per page</label>
              </div>
            </div>
          )}
        </div>

        <DataAddModal
          show={showDataAddModal}
          onHide={() => handleDataAddModal(false, null)}
          formId={id}
          template={template}
          getFormInfo={getFormInfo}
          updateMessage={updateMessage}
          formName={formName}
        />

        <DataEditModal
          show={showDataEditModal}
          onHide={() => handleDataEditModal(false, null)}
          dataInfo={dataInfoToModify}
          formId={id}
          template={template}
          getFormInfo={getFormInfo}
          updateMessage={updateMessage}
        />

        <DataDeleteModal
          show={showDataDeleteModal}
          onHide={() => handleDataDeleteModal(false, null)}
          dataInfo={dataInfoToModify}
          formId={id}
          formName={formName}
          getFormInfo={getFormInfo}
          updateMessage={updateMessage}
        />

        <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
          requireConfirmation={requireConfirmation}
        />

        <AppFooter />
      </>
    );
  }
};
