import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { Button, ListGroup } from "react-bootstrap";
import UserDeleteModal from "../../components/userDelete";
import MessagePopup from "../../components/messagePopup";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import Loader from "../../common/loader";
import { useParams } from "react-router-dom";
import { getRawOrgInfo } from "../../hooks/orgInfo";
import { FaCircleCheck } from "react-icons/fa6";
import ResendEmailModal from "../resendEmail";
import UsersAddModal from "../new-usersAdd";
import UsersEditModal from "../new-usersEdit";
import { setOrgData } from "../../store/orgSlice";

const AccountManagement = ({ editMode, setEditMode }) => {
  const { id } = useParams();
  const [orgInfo, setOrgInfo] = useState(
    useSelector((state) => state.org.orgData)
  );
  const userInfo = useSelector((state) => state.user.userInfo) || {}; // Ensure userInfo is an object

  const users = orgInfo?.users || [];
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const dispatch = useDispatch();

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  const getOrgInfo = () => {
    dispatch(showLoader());
    getRawOrgInfo()
      .then((response) => {
        let orgData = response.data?.find(
          (item) => item.orgId === parseInt(id, 10)
        );
        if (orgData) {
          dispatch(setOrgData(orgData));
          setOrgInfo(orgData);
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.error("Error fetching organization info:", error);
        dispatch(hideLoader());
      });
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  return (
    <div className="account-management-container">
      <Loader />
      <MessagePopup
        message={message}
        messageType={messageType}
        onClose={() => setMessage("")}
      />
      <div className="header d-flex account-man-mn">
        <h2>Account Management</h2>
        {(userInfo.role === "Ensaras Admin" ||
          userInfo.role === "Client Admin") && (
          <div className="account-edit-btn">
            <Button
              variant="outline-primary"
              onClick={() => setEditMode(!editMode)}
            >
              {editMode ? "Finish Edit" : "Edit Accounts"}
            </Button>
          </div>
        )}
      </div>

      <div className="user-list-section">
        {(userInfo.role === "Ensaras Admin" ||
          userInfo.role === "Client Admin") &&
          !editMode && (
            <div className="d-flex account-user-btns">
              <Button
                variant="outline-primary add-user"
                onClick={() => setShowAddModal(true)}
              >
                Add User
              </Button>
              <Button
                variant="outline-primary acc-resend-verify"
                onClick={() => setShowResendEmailModal(true)}
              >
                Resend Verification
              </Button>
            </div>
          )}
        <div className="account-list-outers">
          {orgInfo.orgName === "Ensaras" ? (
            <h4>Ensaras Admins</h4>
          ) : (
            <h4>Admins</h4>
          )}
          <div className="account-list-grp">
            <ListGroup className="account-list-outer">
              {users
                .filter((user) => user.is_admin)
                .map((user) => (
                  <ListGroup.Item
                    key={user.userId}
                    className="d-flex acc-box justify-content-between align-items-center"
                  >
                    <div className="account-name-sym">
                      <em>{user.userEmail} </em>
                      {user.is_email_verified ? (
                        <span>
                          <FaCircleCheck className="input-icon verified" />
                        </span>
                      ) : (
                        <span className="pending-verification">
                          <FaCircleCheck className="input-icon pending-verification" />
                        </span>
                      )}
                    </div>
                    {editMode && (
                      <div className="acc-edit-buttons">
                        <Button
                          variant="link"
                          onClick={() => handleEditUser(user)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="link"
                          // className="text-danger"
                          onClick={() => {
                            setSelectedUser(user);
                            setShowDeleteModal(true);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </div>
        </div>

        {orgInfo.orgName !== "Ensaras" && (
          <div className="account-list-outers">
            <h4 className="mt-4">Normal Users</h4>
            <div className="account-list-grp">
              <ListGroup className="account-list-outer">
                {users
                  .filter((user) => !user.is_admin)
                  .map((user) => (
                    <ListGroup.Item
                      key={user.userId}
                      className="d-flex acc-box justify-content-between align-items-center"
                    >
                      <div className="account-name-sym">
                        <em> {user.userEmail} </em>

                        {user.is_email_verified ? (
                          <span>
                            <FaCircleCheck className="input-icon verified" />
                          </span>
                        ) : (
                          <span className="pending-verification">
                            <FaCircleCheck className="input-icon pending-verification" />
                          </span>
                        )}
                      </div>
                      {editMode && (
                        <div className="acc-edit-buttons">
                          <Button
                            variant="link"
                            onClick={() => handleEditUser(user)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="link"
                            // className="text-danger"
                            onClick={() => {
                              setSelectedUser(user);
                              setShowDeleteModal(true);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      )}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </div>
          </div>
        )}
        {orgInfo.orgName === "Ensaras" && (
          <>
            <div className="account-list-outers">
              <h4 className="mt-4">Engineer</h4>
              <div className="account-list-grp">
                <ListGroup className="account-list-outer">
                  {users
                    .filter((user) => user.role === "engineer")
                    .map((user) => (
                      <ListGroup.Item
                        key={user.userId}
                        className="d-flex acc-box justify-content-between align-items-center"
                      >
                        <div className="account-name-sym">
                          <em> {user.userEmail} </em>

                          {user.is_email_verified ? (
                            <span>
                              <FaCircleCheck className="input-icon verified" />
                            </span>
                          ) : (
                            <span className="pending-verification">
                              <FaCircleCheck className="input-icon pending-verification" />
                            </span>
                          )}
                        </div>
                        {editMode && (
                          <div className="acc-edit-buttons">
                            <Button
                              variant="link"
                              onClick={() => handleEditUser(user)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="link"
                              // className="text-danger"
                              onClick={() => {
                                setSelectedUser(user);
                                setShowDeleteModal(true);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
            </div>

            <div className="account-list-outers">
              <h4 className="mt-4">Project Analytics</h4>
              <div className="account-list-grp">
                <ListGroup className="account-list-outer">
                  {users
                    .filter((user) => user.role === "project_analytics")
                    .map((user) => (
                      <ListGroup.Item
                        key={user.userId}
                        className="d-flex acc-box justify-content-between align-items-center"
                      >
                        <div className="account-name-sym">
                          <em> {user.userEmail} </em>

                          {user.is_email_verified ? (
                            <span>
                              <FaCircleCheck className="input-icon verified" />
                            </span>
                          ) : (
                            <span className="pending-verification">
                              <FaCircleCheck className="input-icon pending-verification" />
                            </span>
                          )}
                        </div>
                        {editMode && (
                          <div className="acc-edit-buttons">
                            <Button
                              variant="link"
                              onClick={() => handleEditUser(user)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="link"
                              // className="text-danger"
                              onClick={() => {
                                setSelectedUser(user);
                                setShowDeleteModal(true);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
            </div>

            <div className="account-list-outers">
              <h4 className="mt-4">Tech Stack</h4>
              <div className="account-list-grp">
                <ListGroup className="account-list-outer">
                  {users
                    .filter((user) => user.role === "tech_stack")
                    .map((user) => (
                      <ListGroup.Item
                        key={user.userId}
                        className="d-flex acc-box justify-content-between align-items-center"
                      >
                        <div className="account-name-sym">
                          <em> {user.userEmail} </em>

                          {user.is_email_verified ? (
                            <span>
                              <FaCircleCheck className="input-icon verified" />
                            </span>
                          ) : (
                            <span className="pending-verification">
                              <FaCircleCheck className="input-icon pending-verification" />
                            </span>
                          )}
                        </div>
                        {editMode && (
                          <div className="acc-edit-buttons">
                            <Button
                              variant="link"
                              onClick={() => handleEditUser(user)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="link"
                              // className="text-danger"
                              onClick={() => {
                                setSelectedUser(user);
                                setShowDeleteModal(true);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
            </div>
          </>
        )}
      </div>
      <ResendEmailModal
        show={showResendEmailModal}
        onHide={() => setShowResendEmailModal(false)}
        updateMessage={updateMessage}
      />
      <UsersAddModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        orgId={id}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
      <UsersEditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        userId={selectedUser?.userId}
        isEmailVerified={selectedUser?.is_email_verified}
        orgId={id}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
      <UserDeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        username={selectedUser?.userEmail}
        userId={selectedUser?.userId}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
    </div>
  );
};

export default AccountManagement;
