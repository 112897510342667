import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Col, ListGroup } from "react-bootstrap";
import { addUser } from "../../hooks/userAdd.js";
import { getRawTeamInfo } from "../../hooks/teamInfo.js";
import { isEmail } from "validator";
import "react-phone-number-input/style.css";
import Select from "react-select";
import { RxCrossCircled } from "react-icons/rx";
import { showLoader, hideLoader } from "../../store/loadingSlice.js";
import Loader from "../../common/loader.js";
import { useDispatch, useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import "./style.css";
import { Reminder } from "../../utils/validation.js";

const UsersAddModal = ({ show, onHide, orgId, getOrgInfo, updateMessage }) => {
  const orgInfo = useSelector((state) => state.org.orgData);
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamsInfo, setTeamsInfo] = useState(null);
  const [roles, setRoles] = useState(["Admin", "Normal User"]);
  const [allOrgs, setAllOrgs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    assignedTeams: [],
    permissions: [],
    role: "",
    orgs: [],
  });
  const [errors, setErrors] = useState({});
  const [selectedOrgs, setSelectedOrgs] = useState([
    { orgId: null, teams: [] },
  ]);

  useEffect(() => {
    if (show) {
      dispatch(showLoader());
      getRawTeamInfo()
        .then((response) => {
          setTeamsInfo(response.data);
          dispatch(hideLoader());
        })
        .catch((error) => {
          updateMessage("error", `[${error.message}] Failed to get team info!`);
          dispatch(hideLoader());
        });
    }
  }, [show, dispatch, updateMessage]);

  useEffect(() => {
    if (teamsInfo && orgId) {
      const currentOrg = teamsInfo.find(
        (item) => Number(item.orgId) === Number(orgId)
      );
      if (currentOrg?.teams?.length > 0) {
        setTeams(
          currentOrg.teams
            .filter((team) => team.teamName !== "Master Data Management")
            .map((team) => ({
              value: team.teamId,
              label: team.teamName,
            }))
        );
      }
    }
  }, [orgId, teamsInfo]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target || e;
    const updatedFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name === "role") {
      if (value === "Admin") {
        updatedFormData.permissions = ["Can Edit", "Can Delete", "Can Add"];
      } else {
        updatedFormData.permissions = [];
        setSelectedTeams([]);
        formData.assignedTeams = [];
      }
      if (value === "Engineer" || value === "Project Analytics") {
        const getAllOrgs = teamsInfo
          .filter((org) => org.orgName !== "Ensaras")
          .map((org) => ({
            value: org.orgId,
            label: org.orgName,
          }));
        setAllOrgs(getAllOrgs);
        setSelectedOrgs([{ orgId: null, teams: [] }]);
      }
    }

    setFormData(updatedFormData);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleOrgChange = (index, selectedOption) => {
    const updatedOrgs = [...selectedOrgs];
    if (selectedOption) {
      updatedOrgs[index].orgId = selectedOption.value;
      const orgTeams = teamsInfo.find(
        (org) => org.orgId === selectedOption.value
      );
      if (orgTeams) {
        updatedOrgs[index].availableTeams = orgTeams.teams
          .filter((team) => team.teamName !== "Master Data Management")
          .map((team) => ({
            value: team.teamId,
            label: team.teamName,
          }));
        updatedOrgs[index].teams = []; // Cle
      }
    } else {
      updatedOrgs[index].orgId = null;
      updatedOrgs[index].availableTeams = [];
      updatedOrgs[index].teams = []; // Clear selected teams
    }
    setSelectedOrgs(updatedOrgs);
  };

  const handleRemoveOrg = (index) => {
    const updatedOrgs = selectedOrgs.filter((_, i) => i !== index);
    setSelectedOrgs(updatedOrgs);
  };

  const handleAddAnotherOrg = () => {
    setSelectedOrgs([...selectedOrgs, { orgId: null, teams: [] }]);
  };

  const handleTeamChange = (index, selectedOption) => {
    if (orgInfo && orgInfo.orgName === "Ensaras") {
      const updatedOrgs = [...selectedOrgs];
      const teamId = selectedOption.value;
      if (!updatedOrgs[index].teams) {
        updatedOrgs[index].teams = [];
      }
      if (!updatedOrgs[index].teams.some((t) => t.value === teamId)) {
        updatedOrgs[index].teams.push(selectedOption);
      }
      setSelectedOrgs(updatedOrgs);
    } else {
      const teamId = selectedOption.value;
      if (!selectedTeams.some((t) => t.teamId === teamId)) {
        const updatedSelectedTeams = [
          ...selectedTeams,
          { teamId, teamName: selectedOption.label },
        ];
        setSelectedTeams(updatedSelectedTeams);
        setFormData((prevData) => ({
          ...prevData,
          assignedTeams: updatedSelectedTeams.map((team) => team.teamId),
        }));
      }
      if (errors.assignedTeams) {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.assignedTeams;
          return newErrors;
        });
      }
    }
  };

  const handleRemoveSelectedTeam = (orgIndex, teamId) => {
    if (orgInfo && orgInfo.orgName === "Ensaras") {
      const updatedOrgs = [...selectedOrgs];
      updatedOrgs[orgIndex].teams = updatedOrgs[orgIndex].teams.filter(
        (team) => team.value !== teamId
      );
      setSelectedOrgs(updatedOrgs);
    } else {
      const updatedTeams = selectedTeams.filter(
        (team) => team.teamId !== teamId
      );
      setSelectedTeams(updatedTeams);
      setFormData((prevData) => ({
        ...prevData,
        assignedTeams: updatedTeams.map((team) => team.teamId),
      }));
    }
  };

  const validateForm = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      role,
    } = formData;
    const newErrors = {};

    if (!firstName.trim()) {
      newErrors.firstName = "First name is required.";
    }
    if (!lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    }
    const nameRegex = /^[a-zA-Z'_ -]+$/;
    if (firstName && !nameRegex.test(firstName)) {
      newErrors.firstName =
        "First name can only contain letters, hyphens, apostrophes, spaces, and underscores.";
    }
    if (lastName && !nameRegex.test(lastName)) {
      newErrors.lastName =
        "Last name can only contain letters, hyphens, apostrophes, spaces, and underscores.";
    }
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    }
    if (email && !isEmail(email)) {
      newErrors.email = "Email is invalid.";
    }
    if (password.trim() === "") {
      newErrors.password = "Password is required.";
    } else if (
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/
      )
    ) {
      if (password.trim() === "") {
        newErrors.password =
          "Password must have at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.";
      } else if (password.length < 8) {
        newErrors.password = "Password must have at least 8 characters.";
      } else if (!password.match(/[a-z]/)) {
        newErrors.password = "Password must have at least 1 lowercase letter.";
      } else if (!password.match(/[A-Z]/)) {
        newErrors.password = "Password must have at least 1 uppercase letter.";
      } else if (!password.match(/\d/)) {
        newErrors.password = "Password must have at least 1 number.";
      } else if (!password.match(/[a-zA-Z]/)) {
        newErrors.password = "Password must have at least 1 letter.";
      } else if (!password.match(/[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]/)) {
        newErrors.password = "Password must have at least 1 special character.";
      }
    }

    if (!role) newErrors.role = "Role is required.";
    if (orgInfo && orgInfo.orgName !== "Ensaras" && role === "Normal User") {
      if (selectedTeams && selectedTeams.length === 0)
        newErrors.assignedTeams = "Please select at least one team.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    let userClone = {
      orgId: Number(orgId),
      first_name: formData.firstName,
      last_name: formData.lastName,
      password: formData.password,
      email: formData.email,
      is_admin: formData.role === "Admin" ? true : false,
      can_edit:
        formData.role === "Admin"
          ? true
          : formData.permissions.includes("Can Edit"),
      can_delete:
        formData.role === "Admin"
          ? true
          : formData.permissions.includes("Can Delete"),
      can_add:
        formData.role === "Admin"
          ? true
          : formData.permissions.includes("Can Add"),
    };

    if (orgInfo && orgInfo.orgName === "Ensaras") {
      userClone = {
        ...userClone,
        role:
          formData.role === "Admin"
            ? "admin"
            : formData.role.toLowerCase().replace(/\s+/g, "_"),
        teams: selectedOrgs.flatMap((org) =>
          org.teams.map((team) => team.value)
        ),
      };
      if (
        formData.role === "Engineer" ||
        formData.role === "Project Analytics" ||
        formData.role === "Tech Stack"
      ) {
        const ensarasOrg = teamsInfo.find((org) => org.orgName === "Ensaras");
        if (ensarasOrg) {
          const ensarasTeam = ensarasOrg.teams.find(
            (team) => team.teamName === formData.role
          );
          if (ensarasTeam) {
            userClone.teams.push(ensarasTeam.teamId);
          }
        }
      }
    } else {
      userClone = {
        ...userClone,
        teams: formData.assignedTeams,
        role: formData.role === "Admin" ? "admin" : "user",
      };
      if (formData.role === "Admin") {
        userClone.teams =
          teamsInfo
            .find((org) => Number(org.orgId) === Number(orgId))
            ?.teams.map((team) => team.teamId) || [];
      }
    }

    dispatch(showLoader());
    setIsSubmitting(true);
    addUser(userClone)
      .then((response) => {
        handleModalHide();
        setIsSubmitting(false);
        getOrgInfo();
        updateMessage(
          "success",
          response?.message || "User added successfully."
        );
        dispatch(hideLoader());
      })
      .catch((error) => {
        updateMessage("error", error.message || "Failed to add user.");
        dispatch(hideLoader());
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (orgInfo && orgInfo.orgName === "Ensaras") {
      setRoles(["Admin", "Tech Stack", "Engineer", "Project Analytics"]);
    }
  }, [orgInfo]);

  const handleModalHide = () => {
    setFormData({
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      assignedTeams: [],
      role: "",
      permissions: [],
      orgs: [],
    });
    setSelectedTeams([]);
    setSelectedOrgs([{ orgId: null, teams: [] }]);
    setErrors({});
    onHide();
  };

  return (
    <Modal className="add-popup" show={show} onHide={handleModalHide} centered>
      <Loader />
      <Modal.Header closeButton>
        <Modal.Title>Add User Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* First Name */}
          <Form.Group className="modal-fld">
            <Form.Label>
              First Name <span className="text-danger">*</span>
            </Form.Label>
            <div className="txx-fld">
              <Form.Control
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                isInvalid={!!errors.firstName}
                placeholder="Enter First Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          {/* Last Name */}
          <Form.Group className="modal-fld">
            <Form.Label>
              Last Name <span className="text-danger">*</span>
            </Form.Label>
            <div className="txx-fld">
              <Form.Control
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                isInvalid={!!errors.lastName}
                placeholder="Enter Last Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          {/* Email */}
          <Form.Group className="modal-fld">
            <Form.Label>
              Email <span className="text-danger">*</span>
            </Form.Label>
            <div className="txx-fld">
              <Form.Control
                name="email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
                placeholder="Enter Email"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          {/* Password */}
          <Form.Group className="modal-fld tp-align">
            <Form.Label>
              Password <span className="text-danger">*</span>
            </Form.Label>
            <div className="txx-fld">
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
                placeholder="Enter Password"
              />
              {!errors.password && (
                <Form.Text className="text-muted">
                  {Reminder("password")}
                </Form.Text>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          {/* Role */}
          <Form.Group
            controlId="roles"
            className="align-items-center modal-fld"
          >
            <Form.Label>
              Role <span className="text-danger">*</span>
            </Form.Label>
            <Col>
              <div className="role-gtp">
                {roles.map((role) => (
                  <label
                    key={role}
                    className={`custom-radio ${errors.role ? "invalid" : ""}`}
                  >
                    <span>{role}</span>
                    <Form.Check
                      className="check-inner"
                      type="radio"
                      name="role"
                      value={role}
                      checked={formData.role === role}
                      onChange={handleChange}
                      isInvalid={!!errors.role}
                    />
                  </label>
                ))}
              </div>
              {errors.role && (
                <div className="invalid-feedback d-block">{errors.role}</div>
              )}
            </Col>
          </Form.Group>

          {orgInfo &&
            orgInfo.orgName === "Ensaras" &&
            formData.role &&
            formData.role !== "Admin" &&
            formData.role !== "Tech Stack" && (
              <>
                {selectedOrgs.map((org, index) => (
                  <div
                    key={index}
                    className={`${index !== 0 ? "another-org" : ""}`}
                  >
                    {index !== 0 && (
                      <span
                        className="cross-icon"
                        onClick={() => handleRemoveOrg(index)}
                      >
                        <RxCross2 />
                      </span>
                    )}
                    <Form.Group
                      controlId={`orgs-${index}`}
                      className="modal-fld tp-align"
                    >
                      {index === 0 && (
                        <Form.Label>
                          Assigned Organizations{" "}
                          {/* <span className="text-danger">*</span> */}
                        </Form.Label>
                      )}

                      {index !== 0 && <label></label>}

                      <div className="txx-fld">
                        <Col>
                          <Select
                            name={`orgs-${index}`}
                            value={
                              allOrgs.find((o) => o.value === org.orgId) || null
                            }
                            onChange={(option) =>
                              handleOrgChange(index, option)
                            }
                            options={allOrgs}
                            placeholder="Select Orgs"
                            isInvalid={!!errors[`selectedOrgs_${index}`]}
                            isOptionDisabled={(option) =>
                              selectedOrgs.some(
                                (o) =>
                                  o.orgId === option.value &&
                                  o.orgId !== org.orgId
                              )
                            }
                            classNamePrefix={
                              !!errors[`selectedOrgs_${index}`]
                                ? "invalid"
                                : "select-dropdown"
                            }
                            menuPlacement="auto"
                            isClearable
                          />
                          {errors[`selectedOrgs_${index}`] && (
                            <div className="invalid-feedback d-block">
                              {errors[`selectedOrgs_${index}`]}
                            </div>
                          )}
                        </Col>
                      </div>
                    </Form.Group>

                    <div className="org-teams">
                      <Form.Group
                        controlId="assignedTeams"
                        className="modal-fld tp-align"
                      >
                        <label> </label>
                        <div className="txx-fld">
                          <Col>
                            <Select
                              name="teams"
                              value={null}
                              onChange={(option) =>
                                handleTeamChange(index, option)
                              } // Use index 0 for non-Ensaras
                              options={org.availableTeams || []}
                              placeholder="Select Teams"
                              isInvalid={!!errors[`assignedTeams_${index}`]}
                              isOptionDisabled={(option) =>
                                org.teams.some((f) => f.value === option.value)
                              }
                              classNamePrefix={
                                !!errors[`assignedTeams_${index}`]
                                  ? "invalid"
                                  : "select-dropdown"
                              }
                              menuPlacement="auto"
                              isClearable
                            />
                            {errors[`assignedTeams_${index}`] && (
                              <div className="invalid-feedback d-block">
                                {errors[`assignedTeams_${index}`]}
                              </div>
                            )}
                            <div className="assigned-teams-box mt-3">
                              <ListGroup>
                                <ListGroup.Item className="assigned-teams-label">
                                  <div className="team-title">
                                    Assigned Teams
                                  </div>
                                  <div className="team-items-name">
                                    {org.teams.map((team, teamIndex) => (
                                      <ListGroup.Item
                                        key={teamIndex}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <span className="team-name-title">
                                          {" "}
                                          {team.label}
                                        </span>

                                        <Button
                                          variant="link"
                                          className="text-danger"
                                          onClick={() =>
                                            handleRemoveSelectedTeam(
                                              index,
                                              team.value
                                            )
                                          }
                                        >
                                          <RxCrossCircled />
                                        </Button>
                                      </ListGroup.Item>
                                    ))}
                                  </div>
                                </ListGroup.Item>
                              </ListGroup>
                            </div>
                          </Col>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                ))}
                {formData.role !== "Tech Stack" && (
                  <Button
                    variant="link"
                    onClick={handleAddAnotherOrg}
                    disabled={
                      !selectedOrgs[selectedOrgs.length - 1].orgId ||
                      selectedOrgs[selectedOrgs.length - 1].teams.length === 0
                    }
                  >
                    + Add another Organization
                  </Button>
                )}
              </>
            )}

          {orgInfo &&
            orgInfo.orgName !== "Ensaras" &&
            formData.role === "Normal User" && (
              <>
                <Form.Group
                  controlId="assignedTeams"
                  className="modal-fld tp-align"
                >
                  <Form.Label>
                    Assigned Teams <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="txx-fld">
                    <Col>
                      <Select
                        name="teams"
                        value=""
                        onChange={(option) => handleTeamChange(0, option)} // Use index 0 for non-Ensaras
                        options={teams}
                        placeholder="Select Teams"
                        isInvalid={!!errors.assignedTeams}
                        isOptionDisabled={(option) =>
                          selectedTeams.some((f) => f.teamId === option.value)
                        }
                        classNamePrefix={
                          !!errors.forms ? "invalid" : "select-dropdown"
                        }
                        menuPlacement="auto"
                        isClearable
                      />
                      {errors.assignedTeams && (
                        <div className="invalid-feedback d-block">
                          {errors.assignedTeams}
                        </div>
                      )}
                      <div className="assigned-teams-box mt-3">
                        <ListGroup>
                          <ListGroup.Item className="assigned-teams-label">
                            <div className="team-title">Assigned Teams</div>
                            <div className="team-items-name">
                              {selectedTeams.map((team, index) => (
                                <ListGroup.Item
                                  key={index}
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  {/* {team.teamName} */}
                                  <span className="team-name-title">
                                    {team.teamName}
                                  </span>
                                  <Button
                                    variant="link"
                                    className="text-danger"
                                    onClick={() =>
                                      handleRemoveSelectedTeam(0, team.teamId)
                                    }
                                  >
                                    <RxCrossCircled />
                                  </Button>
                                </ListGroup.Item>
                              ))}
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </Col>
                  </div>
                </Form.Group>
              </>
            )}
          {formData.role !== "Ensaras User" && (
            <Form.Group controlId="permissions" className="tp-align modal-fld">
              <Form.Label>Permissions</Form.Label>
              <Col>
                <div className="permissions-gtp">
                  {["Can Add", "Can Edit", "Can Delete"].map((permission) => (
                    <label
                      key={permission}
                      className={`custom-checkbox ${
                        errors.permissions ? "invalid" : ""
                      }`}
                    >
                      <span>{permission}</span>
                      <Form.Check
                        className="check-inner"
                        name="permissions"
                        value={permission}
                        checked={formData.permissions.includes(permission)}
                        onChange={(e) => {
                          if (formData.role !== "Admin") {
                            const isChecked = e.target.checked;
                            setFormData((prevData) => ({
                              ...prevData,
                              permissions: isChecked
                                ? [...prevData.permissions, permission]
                                : prevData.permissions.filter(
                                    (p) => p !== permission
                                  ),
                            }));
                          }
                        }}
                      />
                    </label>
                  ))}
                </div>
              </Col>
            </Form.Group>
          )}
        </Form>

        <Button
          variant="primary"
          className="save-btn"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default UsersAddModal;
