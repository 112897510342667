import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config.js";

const MasterDataManagement = () => {
  const orgInfo = useSelector((state) => state.org.orgData);
  const [grafanaId, setGrafanaId] = useState(null);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    setGrafanaId(orgInfo.grafanaId);
    if (orgInfo && orgInfo.teams && orgInfo.teams.length > 0) {
      const teams = orgInfo.teams.filter(
        (team) => team.teamName === "Master Data Management"
      );
      setTeams(teams);
    }
  }, [orgInfo]);

  const handleGrafana = () => {
    window.location.href = `${config.apiUrl}/grafana_oidc/${grafanaId}`;
  };
  return (
    <div>
      <div key={teams[0]?.teamId} className="team-container master-container">
        <div className="team-button-list">
          <button className="daskbtn" onClick={() => handleGrafana()}>
            Dashboard
          </button>
        </div>
        <div className="submit-forms-mn">
          <h4>Submit Forms</h4>
          {teams[0]?.teamName === "Master Data Management" && (
            <div
              className="submit-form-outer"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: "10px",
              }}
            >
              {teams[0]?.forms?.map((form) => (
                <div className="submit-form-box" key={form.formId}>
                  <div className="team-title-btn">
                    <a href={`/form/${form.formId}?orgId=${orgInfo.orgId}`}>
                      {form.formName}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MasterDataManagement;
