import React, { useState, useEffect } from "react";
import { FcCancel } from "react-icons/fc";
import "./style.css";
import { userauthentication } from "../../hooks/userAuthentication";

const UserAuthentication = ({ show }) => {
  const [verificationStatus, setVerificationStatus] = useState(null);
  // const [error, setError] = useState("");
  // const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState(null);


  // Extract token from URL path
  const idToken = window.location.pathname;
  const token = idToken.split("/")[2]; // Adjust this based on your URL structure

  const handleVerifyEmail = async () => {
    const user_id = localStorage.getItem("userId");
    userauthentication({ token: token, requested_user_id: user_id })
      .then((res) => {
        setVerificationStatus("success");
        const redirectionPath = res.redirect_url;
        window.location.href = redirectionPath; // Redirect to login page after a delay
      })
      .catch((error) => {
        setVerificationStatus("error");
        // setError(`[${error.message}] Failed to verify user!`);
        // setVariant("danger");
        setMessage(`${error.message}`);
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/signin";
        }, 5000);
      });
  };

  useEffect(() => {
    if (token) {
      handleVerifyEmail();
    }
  }, []);

  return (
    <div
      className="verification-page-container"
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div className="verification-content" style={{ width: "100%" }}>
        {!verificationStatus && (
          <h3>Please wait while we verify your email...</h3>
        )}
        {verificationStatus === "error" && (
          <div>
            <div
              style={{
                color: "red",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <FcCancel size={200} style={{ marginRight: "10px" }} />
            </div>
            <div
              style={{
                color: "red",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <h3>{message}</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAuthentication;
